import React, { useState } from 'react';
import {
  StyleSheet, View, Text,
} from 'react-native';
import moment from 'moment';
import _ from 'lodash';
import {
  Radio, RadioGroup, Input,
} from '@ui-kitten/components';
import DayPicker from 'react-day-picker';
import '../../style/DayPicker.css';

import { trigger } from 'swr';
import { Call } from '../../types';
import Style from '../../style';
import I18n from '../../i18n';
import Button from '../Button';
import { moveCall } from '../../api/Calls';
import BoxNotification from '../BoxNotification';
import ButtonSmall from '../ButtonSmall';
import TimePicker, { START_HOUR } from './TimePicker';
import { getDefaultCallStart } from '../../util/helpers';

const SUCCESS = 'success';

const getCallMoveReasons = (callStart?: Date) => (moment(callStart).isAfter()
  ? ['clientRequest', 'other']
  : ['clientNoShow', 'techIssues', 'other']);

interface CallMoveModalProps {
  call: Call;
  fetchCallsUrl: string;
  closeModal: () => void;
}
const CallMoveModal = ({
  call, fetchCallsUrl, closeModal,
}: CallMoveModalProps) => {
  const [selectedDate, setSelectedDate] = useState(getDefaultCallStart(START_HOUR, call).toDate());
  const [selectedTime, setSelectedTime] = useState(getDefaultCallStart(START_HOUR, call).format('H:mm'));

  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [reasonIndex, setReasonIndex] = React.useState(0);
  const [notes, setNotes] = React.useState('');
  const [apiRes, setApiRes] = useState('');

  const callMoveReasons = getCallMoveReasons(call.start);

  const pressConfirm = async () => {
    setApiRes('');
    const start = moment(selectedDate)
      .set({
        hours: Number(selectedTime.split(':')[0]),
        minutes: Number(selectedTime.split(':')[1]),
        seconds: 0,
      }).toDate();

    const reason = callMoveReasons[reasonIndex];
    setLoadingConfirm(true);
    try {
      const res = await moveCall(call.id, start, reason, notes);
      if (res) await trigger(fetchCallsUrl);
      setApiRes(SUCCESS);
      setTimeout(() => { closeModal(); }, 2000);
    } catch (err) {
      if (err instanceof Error) {
        setApiRes(err.message.toString());
        setTimeout(() => { setApiRes(''); }, 4300);
      }
    }
    setLoadingConfirm(false);
  };

  const handleDayClick = (day: Date, modifiers: DayPicker.DayModifiers) => {
    if (modifiers.disabled) {
      return;
    }
    setSelectedDate(day);
  };

  const handleTimeChange = (time: string) => {
    setSelectedTime(time);
  };

  const isButtonDisabled = () => {
    if (moment(call.start).isBefore() && notes === '') return true;
    if (notes === '' && reasonIndex === 1) return true;
    return false;
  };

  const renderCalendar = () => (
    <View style={styles.calendar}>
      <DayPicker
        onDayClick={handleDayClick}
        disabledDays={{ before: new Date() }}
        fromMonth={new Date()}
        selectedDays={selectedDate}
        initialMonth={selectedDate}
        firstDayOfWeek={1}
        weekdaysShort={moment.weekdaysShort()}
        weekdaysLong={moment.weekdays()}
      />
      <TimePicker selectedTime={selectedTime} onTimeChange={handleTimeChange} />
    </View>
  );

  const renderCallMoveReason = () => {
    if (!call) return null;

    return (
      <View>
        <RadioGroup
          style={styles.radioGroup}
          selectedIndex={reasonIndex}
          onChange={(index) => setReasonIndex(index)}
        >
          {_.map(callMoveReasons, (moveReason) => (
            <Radio style={styles.radioButton} key={moveReason}>
              {(evaProps) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <Text {...evaProps} style={styles.radioButtonText}>
                  {I18n.t(`ui.calls.${moveReason}`)}
                </Text>
              )}
            </Radio>
          ))}
        </RadioGroup>
        <Input
          key="explain"
          style={styles.input}
          value={notes}
          placeholder={I18n.t('ui.calls.shortExplanation')}
          onChangeText={setNotes}
          textStyle={styles.inputText}
        />
      </View>
    );
  };

  const renderNotificationMsg = () => {
    const isSuccess = apiRes === SUCCESS;
    return (
      <BoxNotification
        style={isSuccess ? styles.apiResPos : {}}
        autoInvisible={!isSuccess}
      >
        <View style={{ flexDirection: 'row' }}>
          {isSuccess && <Style.Icon.ByName name="CheckCircle" width={24} height={24} fill={Style.Color.White} />}
          {isSuccess
            ? <Text style={styles.notificationText}>{I18n.t('ui.calls.moveCallSuccess')}</Text>
            : <Text style={styles.notificationText}>{apiRes}</Text>}
        </View>
      </BoxNotification>
    );
  };

  const renderCloseButton = () => (
    <View style={styles.closeButton}>
      {/* After pressing 'Confirm', when API response is shown (e.g. 'call moved, email sent'),
      do not show 'close' button */}
      {!apiRes && (
        <ButtonSmall
          icon="X"
          onPress={closeModal}
          iconSize={24}
          testId="CallMove.CloseModalButton"
        />
      )}
    </View>
  );

  return (
    <View style={styles.container}>
      {renderCloseButton()}
      <Text style={styles.header}>{I18n.t('ui.calls.moveCall')}</Text>
      <Text style={styles.moveCallWaring}>{I18n.t('ui.calls.moveCallWarning')}</Text>
      {renderCalendar()}
      <Text style={styles.reasonTitle}>{I18n.t('ui.calls.reason')}</Text>
      {renderCallMoveReason()}
      <View style={styles.buttonPad}>
        <Button
          onPress={pressConfirm}
          title={I18n.t('ui.buttons.confirm')}
          icon="PaperPlaneRight"
          loading={loadingConfirm}
          testId="CallMove.ConfirmButton"
          disable={isButtonDisabled()}
        />
      </View>
      {!!apiRes && <View style={styles.disableLayer} />}
      {!!apiRes && renderNotificationMsg()}
    </View>
  );
};

export default CallMoveModal;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    borderRadius: 4,
  },
  header: {
    ...Style.Text.Heading2,
    color: Style.Color.Black,
    textAlign: 'center',
    width: '100%',
    marginBottom: 25,
    zIndex: 10,
  },
  infoElements: {
    flexDirection: 'row',
    marginLeft: 50,
    marginBottom: 15,
    alignItems: 'center',
    zIndex: 10,
  },
  moveCallWaring: {
    ...Style.Text.Normal,
    color: Style.Color.Black,
    maxWidth: '100%',
    paddingHorizontal: 40,
    marginBottom: 20,
    zIndex: 10,
  },
  calendar: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  timeContainer: {
    marginTop: 0,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  timeTextContainer: {
    minWidth: 138,
    minHeight: 30,
    borderRadius: 15,
    backgroundColor: Style.Color.Primary,
    alignItems: 'center',
    justifyContent: 'center',
  },
  timeText: {
    ...Style.Text.Normal,
    color: Style.Color.White,
  },
  btnTimeCircle: {
    width: 30,
    height: 30,
    borderRadius: 15,
    backgroundColor: Style.Color.Gray200,
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    marginHorizontal: 10,
  },
  btnTimeCircleHover: {
    backgroundColor: Style.Color.Gray300,
  },
  btnTimeCirclePressed: {
    backgroundColor: Style.Color.Gray400,
  },
  buttonPad: {
    width: '100%',
    height: 40,
    alignSelf: 'center',
    marginBottom: 24,
    paddingHorizontal: 40,
  },
  reasonTitle: {
    ...Style.Text.Heading4,
    color: Style.Color.Black,
    marginTop: 25,
    marginLeft: 40,
    marginBottom: 5,
    zIndex: 8,
  },
  input: {
    width: '100%',
    paddingHorizontal: 50,
    height: 40,
    marginTop: 10,
    marginBottom: 16,
    backgroundColor: Style.Color.White,
  },
  inputText: {
    ...Style.Text.Normal,
    color: Style.Color.Gray800,
  },
  radioGroup: {
    marginLeft: 50,
  },
  radioButton: {

  },
  radioButtonText: {
    ...Style.Text.Normal,
    color: Style.Color.Black,
    marginLeft: 14,
  },
  apiResPos: {
    backgroundColor: Style.Color.Green,
  },
  notification: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  notificationText: {
    ...Style.Text.Normal,
    color: Style.Color.White,
    marginLeft: 10,
  },
  disableLayer: {
    backgroundColor: Style.Color.White,
    height: '100%',
    width: '100%',
    position: 'absolute',
    opacity: 0.5,
    borderRadius: 4,
  },
  closeButton: {
    width: '100%',
    alignItems: 'flex-end',
    marginTop: 20,
    marginBottom: 4,
    minHeight: 24,
  },
});
