import React, {
  useContext, useState, memo,
} from 'react';
import {
  StyleSheet, View, Text,
} from 'react-native';
import _ from 'lodash';
import moment from 'moment';
import { trigger } from 'swr';

import { RootStoreContext } from '../../stores/RootStore';
import Style from '../../style';
import I18n from '../../i18n';
import LoadingIndicator from '../LoadingIndicator';
import BoxNotification from '../BoxNotification';
import { fetchAvails, saveAvails } from '../../api/Availabilities';
import { fetchCalls } from '../../api/Calls';
import Calendar from './Calendar';
import { Availabilities, Slot } from '../../types/Availabilities';
import Section from '../Section';

const WEEKS = 2;
const WEEKS_IN_MS = WEEKS * 7 * 24 * 60 * 60 * 1000;
const SUCCESS = 'success';
const NUM_DAYS = WEEKS * 7;
const SLOT_LENGTH = 30 * 60 * 1000;

export const globalState = { // exported for testing
  currentSlots: [''],
};

const DailyAvailabilityPicker = () => {
  const store = useContext(RootStoreContext);
  const myId = store.auth.userId;
  if (!myId) return null;

  const baseDay = moment().hours(0).minutes(0).seconds(0)
    .milliseconds(0);
  // initialize blank slots for entire calendar
  const startTime = baseDay.valueOf();
  const endTime = moment(startTime).add(WEEKS, 'weeks').valueOf();

  // populate with last availability list received from API
  const { data: availabilities, error } = fetchAvails(myId, baseDay.valueOf(), WEEKS_IN_MS).swr;
  const availsUrl = fetchAvails(myId, baseDay.valueOf(), WEEKS_IN_MS).url;
  if (availabilities && 'httpStatus' in availabilities) {
    const isAuthFail = store.uiState.checkError(availabilities);
    if (isAuthFail) return null;
  }

  // add call IDs to corresponding slots
  const { data: allCalls } = fetchCalls({
    start: startTime, range: WEEKS_IN_MS, status: 'confirmed', userId: myId,
  }).swr;

  const getSlots = (slotAvails?: Availabilities[]) => {
    const slots: Slot = {};
    for (let ts = startTime; ts < endTime; ts += SLOT_LENGTH) {
      slots[ts] = {};
    }
    const avails = slotAvails || [];
    avails.forEach((avail) => {
      const ts = moment(avail.start).valueOf();
      if (slots[ts]) slots[ts].available = true;
    });
    // amend past availabilities that may not have been included in a PUT response
    const firstTs = avails.length
      ? moment(avails[0].start).valueOf()
      : moment().add(1, 'years').valueOf();
    _.filter(globalState.currentSlots, (ts) => Number(ts) < firstTs).forEach((ts) => {
      if (slots[ts]) slots[ts].available = true;
    });

    _.forEach(allCalls, (call) => {
      const callStart = moment(call.start).valueOf();
      const callEnd = callStart + call.duration;
      _.forEach(slots, (slot, i) => {
        const ts = _.parseInt(i);
        if (Math.max(callStart, ts) < Math.min(callEnd, ts + SLOT_LENGTH)) {
          slot.callId = call.id; // eslint-disable-line no-param-reassign
        }
      });
    });
    return slots;
  };

  const slots = getSlots(availabilities);
  const [apiRes, setApiRes] = useState('');
  const [calKey, setCalKey] = useState(Math.round(Math.random() * 1E10));
  const start = baseDay.clone();

  const onSlotsUpdated = (newSlots: string[]) => {
    globalState.currentSlots = newSlots;
  };

  const resetCalendar = () => {
    globalState.currentSlots = [''];
    setCalKey(Math.round(Math.random() * 1E10));
  };

  const saveToServer = async () => {
    try {
      if (!myId) throw new Error('No userId found!');
      const res = await saveAvails(
        myId,
        baseDay.valueOf(),
        WEEKS_IN_MS,
        globalState.currentSlots,
      );
      if (res) await trigger(availsUrl);
      setApiRes(SUCCESS);
      setTimeout(() => { setApiRes(''); }, 2000);
    } catch (err) {
      if (err instanceof Error) {
        setApiRes(err.message.toString());
        setTimeout(() => { setApiRes(''); }, 2000);
      }
    }
  };

  const renderNotificationMsg = () => {
    const isSuccess = apiRes === SUCCESS;
    return (
      <BoxNotification
        style={isSuccess ? styles.apiRes : {}}
        autoInvisible={!isSuccess}
      >
        <View style={{ flexDirection: 'row' }}>
          {isSuccess && <Style.Icon.ByName name="CheckCircle" width={24} height={24} fill={Style.Color.White} />}
          {isSuccess
            ? <Text style={styles.notificationText}>{I18n.t('ui.availabilities.availabilitiesSaved')}</Text>
            : <Text style={styles.notificationText}>{apiRes}</Text>}
        </View>
      </BoxNotification>
    );
  };

  return (
    <Section
      icon={Style.Icon.Door}
      title={I18n.t('ui.availabilities.availabilitiesCoachingTitle')}
      key="dailyAvailabilityPicker"
    >
      <View style={styles.desc}>
        <Text style={styles.descText}>{I18n.t('ui.availabilities.availabilitiesCoachingDes')}</Text>
      </View>
      {!(availabilities || error) && <LoadingIndicator text={I18n.t('ui.availabilities.loadingTimePreferences')} />}
      <Calendar
        key={calKey}
        slotLength={SLOT_LENGTH}
        startDay={start.toDate()}
        startTime="6:00"
        endTime="24:00"
        numDays={NUM_DAYS}
        onSlotsUpdated={onSlotsUpdated}
        slots={slots}
        resetCalendar={resetCalendar}
        saveToServer={saveToServer}
      />
      {!!apiRes && <View style={styles.disableView} />}
      {!!apiRes && renderNotificationMsg()}
    </Section>
  );
};

export default memo(DailyAvailabilityPicker);

const styles = StyleSheet.create({
  desc: {
    paddingHorizontal: 24,
    paddingTop: 20,
    paddingBottom: 37,
  },
  descText: {
    ...Style.Text.Normal,
    color: Style.Color.Gray600,
  },
  apiRes: {
    backgroundColor: Style.Color.Green,
  },
  notification: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  notificationText: {
    ...Style.Text.Normal,
    color: Style.Color.White,
    marginLeft: 10,
  },
  disableView: {
    bottom: 0,
    width: '100%',
    height: 500,
    position: 'absolute',
    backgroundColor: Style.Color.White,
    opacity: 0.5,
  },
});
