import React, { useContext, useState } from 'react';
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import _ from 'lodash';
import Tooltip from 'react-native-walkthrough-tooltip';

import { RootStoreContext } from '../../stores/RootStore';
import Style from '../../style';
import { formatDate } from '../../i18n';
import { getStatusSymbol, getTooltipInfo, getCheckpointDetails } from '../../util/coachings';
import { Program, User, Coaching } from '../../types';
import { HeaderItem, HeaderItemValue, CheckpointInfo } from '../../types/Coachings';
import { ProgramCheckpoint } from '../../types/Programs';
import { getFullName } from '../../util/users';
import { getBrowserInfo } from '../HeaderBar';
import ModalView from '../ModalView';
import CheckpointModal from '../projects/CheckpointModal';
import { fetchProgramByKey } from '../../api/Programs';

type OtherInfo = {
  [key: string]: string | Date | undefined;
};

export const ROW_HEIGHT = 36;

export const getHeaderItems = (program?: Program, full = false) => {
  if (!program?.checkpoints) return null;
  const defaultItems: HeaderItem[] = full ? [
    { key: 'code', type: 'code', width: 80 },
    { key: 'coach', type: 'string', width: 124 },
    // { key: 'invited', type: 'date', width: 80 },
    { key: 'signedUp', type: 'date', width: 124 },
  ] : [];

  const cpItems = program.checkpoints.map((cp: ProgramCheckpoint) => (
    { acronym: cp.acronym, key: cp.key, type: cp.type, progress: cp.progress, width: 60 }
  ));
  const items = _.concat(defaultItems, cpItems);
  return items;
};

interface CoachingStatsProps {
  coachingDetails: Coaching,
  setSelectedCoachingFromChild?: (c?: Coaching) => void;
  showTooltipTitle?: boolean;
  client?: User;
  coach?: User;
  full?: boolean;
  changeInCompact?: boolean;
}

const CoachingStats = ({ coachingDetails, setSelectedCoachingFromChild,
  showTooltipTitle = false, client, coach, full = false,
  changeInCompact = true }: CoachingStatsProps) => {
  const store = useContext(RootStoreContext);
  const { browser, compactView } = getBrowserInfo();
  const [tipKey, setTipKey] = useState('');
  const [selectedCP, setSelectedCP] = useState<CheckpointInfo | undefined>(undefined);
  const { data: program } = fetchProgramByKey(coachingDetails.programKey).swr;

  const isCompactMode = changeInCompact && compactView;

  if (program && 'httpStatus' in program) store.uiState.checkError(program);

  const headerItems = getHeaderItems(program, full);
  if (!headerItems) return null;

  const closeModal = () => {
    setSelectedCP(undefined);
    setTipKey('');
    setSelectedCoachingFromChild?.(undefined);
  };

  const getStats = () => {
    const otherInfo: OtherInfo = {
      code: client?.code,
      coach: getFullName(coach),
      signedUp: client?.created,
    };
    const getValue = (item: HeaderItem) => {
      if (!item.acronym) return otherInfo[item.key];
      return getStatusSymbol(coachingDetails, item);
    };
    const stats = headerItems?.map((item) => ({ value: getValue(item), ...item }));
    return stats;
  };

  const userInfo = getStats();
  if (!userInfo) return null;

  const renderShowInfo = () => {
    if (!selectedCP) return null;
    return (
      <ModalView
        isVisible={!!selectedCP}
        closeModal={closeModal}
        style={browser.height > 400 ? styles.modal : styles.modalWithScroll}
      >
        <CheckpointModal
          itemInfo={selectedCP.itemInfo}
          checkpointDetails={selectedCP.checkpointDetails}
          coaching={coachingDetails}
        />
      </ModalView>
    );
  };

  const renderCell = (item: HeaderItemValue) => {
    if (item.type === 'code') {
      return (
        <View style={styles.userCodeContainer}>
          <Text style={styles.textUserCode}>{item.value}</Text>
        </View>
      );
    }

    if (item.type === 'string') {
      return (
        <Text numberOfLines={1} style={styles.text}>{item.value}</Text>
      );
    }

    if (item.type === 'date') {
      return (
        <Text style={styles.text}>{formatDate('date.dayMonthYearShort', item.value as string)}</Text>
      );
    }

    if (item.type === 'call' || item.type === 'result') {
      const showTip = item.key === tipKey;
      return (
        <Tooltip
          isVisible={showTip}
          disableShadow
          backgroundColor={Style.Color.Transparent}
          contentStyle={styles.toolTip}
          arrowStyle={styles.toolTipArrow}
          content={(
            <View style={styles.toolTipView}>
              {showTooltipTitle && (
                <Text numberOfLines={1} style={styles.toolTipTitle}>
                  {item.acronym}
                </Text>
              )}
              <Text numberOfLines={1} style={styles.toolTipText}>
                {getTooltipInfo(item.key, item.type, coachingDetails)}
              </Text>
            </View>
          )}
          onClose={() => setTipKey('')}
          placement="top"
        >
          <View
            style={styles.statusSymbol}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onMouseEnter={() => { setTipKey(item.key); }}
            onMouseLeave={() => { setTipKey(''); }}
          >
            {renderCellWithIcon(item)}
          </View>
        </Tooltip>
      );
    }

    return null;
  };

  const renderCellWithIcon = (item: HeaderItemValue) => {
    if (item.value instanceof Object && 'icon' in item.value) {
      const isSelected = selectedCP?.itemInfo?.key === item.key;
      const iconSize = (isSelected && !isCompactMode) ? 24 : 20;
      return (

        <TouchableOpacity
          style={[
            isCompactMode && styles.statusViewCompact,
            isSelected && isCompactMode && styles.statusViewCompactSelected,
          ]}
          onPress={() => {
            setTipKey('');
            const cp = getCheckpointDetails(item.key, coachingDetails);
            setSelectedCP({ checkpointDetails: cp, itemInfo: item });
            setSelectedCoachingFromChild?.(coachingDetails);
          }}
        >
          <Style.Icon.Filled.ByName
            name={item.value.icon}
            width={iconSize}
            height={iconSize}
            fill={item.value.color}
          />
          {isCompactMode && <Text style={styles.acronymText}>{item.acronym}</Text>}
        </TouchableOpacity>
      );
    }
    return null;
  };

  const renderCells = () => userInfo.map((info, index) => (
    <View
      key={`${headerItems[index].key}`}
      style={[
        styles.cell,
        { width: headerItems[index].width },
        isCompactMode && styles.cellCompact,
      ]}
    >
      {renderCell(info)}
    </View>
  ));

  return (
    <View style={styles.row}>
      {renderShowInfo()}
      {renderCells()}
    </View>
  );
};

export default CoachingStats;

const styles = StyleSheet.create({
  row: {
    flex: 1,
    flexDirection: 'row',
    height: ROW_HEIGHT,
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  rowCompact: {
    paddingBottom: 30,
  },
  cell: {
    width: 80,
    paddingHorizontal: 4,
    alignItems: 'flex-start',
  },
  cellCompact: {
    width: 86,
    paddingHorizontal: 0,
    marginLeft: 10,
    marginVertical: 2,
  },
  userCodeContainer: {
    backgroundColor: Style.Color.Gray200,
    paddingHorizontal: 4,
    paddingVertical: 2,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
  },
  textUserCode: {
    ...Style.Text.Code,
    color: Style.Color.Gray600,
  },
  text: {
    ...Style.Text.Small,
    color: Style.Color.Black,
  },
  statusSymbol: {
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  statusViewCompact: {
    width: 86,
    height: 40,
    borderRadius: 20,
    backgroundColor: Style.Color.Gray200,
    paddingLeft: 16,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  statusViewCompactSelected: {
    backgroundColor: Style.Color.Gray400,
  },
  acronymText: {
    paddingLeft: 8,
    ...Style.Text.SmallBold,
    color: Style.Color.Black,
  },
  toolTip: {
    borderColor: Style.Color.Gray300,
    borderWidth: 1,
    width: '100%',
  },
  toolTipArrow: {
    borderTopColor: Style.Color.Gray300,
  },
  toolTipView: {
    alignItems: 'center',
  },
  toolTipTitle: {
    ...Style.Text.SmallBold,
    color: Style.Color.Black,
  },
  toolTipText: {
    ...Style.Text.Small,
    color: Style.Color.Black,
  },
  modal: {
    width: 424,
    minHeight: 100,
  },
  modalWithScroll: {
    width: 424,
    flex: 1,
  },
});
