import useSWR from 'swr';
import { ErrorRes, Organization } from '../types';
import { createFetcher } from './Requests';

export const fetchOrganizations = (query = {}) => {
  const { url, fetcher } = createFetcher('/organizations', query);
  return { swr: useSWR<Organization, ErrorRes>(url, fetcher), url };
};

export const fetchOrganizationDetails = (orgId = '', query = {}) => {
  const { url, fetcher } = createFetcher(`/organizations/${orgId}`, query);
  return { swr: useSWR<Organization, ErrorRes>(orgId !== '' ? url : null, fetcher), url };
};
