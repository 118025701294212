import React, { useState } from 'react';
import {
  StyleSheet, View, Text,
} from 'react-native';
import { Input } from '@ui-kitten/components';
import { mutate, trigger } from 'swr';

import { Call } from '../../types';
import Style from '../../style';
import Button from '../Button';
import BoxNotification from '../BoxNotification';
import { obsoleteCall, resetCall } from '../../api/Calls';
import ButtonSmall from '../ButtonSmall';
import { capFirstLetter, getCallCancelModalText, showObsoleteWarning } from '../../util/helpers';
import { fetchModule } from '../../api/Modules';
import { fetchLedgerDetails } from '../../api/Ledgers';

const SUCCESS = 'success';

interface CallCancelModalProps {
  call: Call;
  fetchCallsUrl: string;
  closeModal: () => void;
  isObsoleteSection?: boolean;
}
const CallCancelModal = ({
  call, fetchCallsUrl, closeModal, isObsoleteSection = false,
}: CallCancelModalProps) => {
  const [reason, setReason] = useState('');
  const [apiRes, setApiRes] = useState('');
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const userLedgerUrl = fetchLedgerDetails(call.clientId).url;
  const { data: module } = fetchModule(call.moduleId).swr;
  const modalText = getCallCancelModalText(isObsoleteSection);

  const pressConfirm = async () => {
    setApiRes('');
    setLoadingConfirm(true);
    try {
      const res = isObsoleteSection
        ? await obsoleteCall(call.id, reason)
        : await resetCall(call.id, reason); // reason is sent as notes
      if (res) {
        await trigger(fetchCallsUrl);
        await mutate(userLedgerUrl);
      }
      setApiRes(SUCCESS);
      setTimeout(() => { closeModal(); }, 2000);
    } catch (err) {
      if (err instanceof Error) {
        setApiRes(capFirstLetter(err.message.toString()));
        setTimeout(() => { setApiRes(''); }, 4300);
      }
    }
    setLoadingConfirm(false);
  };

  const renderNotificationMsg = () => {
    const isSuccess = apiRes === SUCCESS;
    return (
      <BoxNotification
        style={isSuccess ? styles.apiResPos : {}}
        autoInvisible={!isSuccess}
      >
        <View style={{ flexDirection: 'row' }}>
          {isSuccess && <Style.Icon.ByName name="CheckCircle" width={24} height={24} fill={Style.Color.White} />}
          {isSuccess
            ? <Text style={styles.notificationText}>{modalText.successNotif}</Text>
            : <Text style={styles.notificationText}>{apiRes}</Text>}
        </View>
      </BoxNotification>
    );
  };

  const renderCloseButton = () => (
    <View style={styles.closeButton}>
      {!apiRes && (
        <ButtonSmall
          icon="X"
          onPress={closeModal}
          iconSize={24}
          testId="CallCancel.CloseModalButton"
        />
      )}
    </View>
  );

  return (
    <View style={styles.container}>
      {renderCloseButton()}
      <Text style={styles.header}>{modalText.title}</Text>
      {modalText.details && <Text style={styles.warning}>{modalText.details}</Text>}
      {showObsoleteWarning(module) && <Text style={styles.warning}>{modalText.warning}</Text>}
      <Text style={styles.reasonTitle}>{modalText.reason}</Text>
      <Input
        testID="CallCancel.ExplainInput"
        key="explain"
        style={styles.input}
        value={reason}
        placeholder={modalText.placeholder}
        onChangeText={setReason}
        textStyle={styles.inputText}
      />
      <View style={styles.buttonPad}>
        <Button
          onPress={pressConfirm}
          title={modalText.buttonTitle}
          icon={modalText.buttonIconName}
          loading={loadingConfirm}
          disable={reason === ''}
        />
      </View>
      {!!apiRes && <View style={styles.disableLayer} />}
      {!!apiRes && renderNotificationMsg()}
    </View>
  );
};

export default CallCancelModal;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    borderRadius: 4,
  },
  header: {
    ...Style.Text.Heading2,
    color: Style.Color.Black,
    textAlign: 'center',
    width: '100%',
    marginBottom: 25,
    zIndex: 10,
  },
  warningContainer: {
    paddingVertical: 40,
    alignItems: 'center',
    zIndex: 10,
  },
  warning: {
    ...Style.Text.Normal,
    color: Style.Color.Black,
    maxWidth: '100%',
    paddingHorizontal: 40,
    marginBottom: 24,
  },
  reasonTitle: {
    ...Style.Text.Heading4,
    color: Style.Color.Black,
    marginLeft: 40,
    marginBottom: 16,
    zIndex: 8,
  },
  input: {
    width: '100%',
    height: 40,
    paddingHorizontal: 50,
    marginBottom: 42,
    alignSelf: 'center',
    backgroundColor: Style.Color.White,
  },
  inputText: {
    ...Style.Text.Normal,
    color: Style.Color.Gray800,
  },
  buttonPad: {
    width: '100%',
    height: 40,
    paddingHorizontal: 40,
    alignSelf: 'center',
    marginBottom: 24,
  },
  apiResPos: {
    backgroundColor: Style.Color.Green,
  },
  notification: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  notificationText: {
    ...Style.Text.Normal,
    color: Style.Color.White,
    marginLeft: 10,
  },
  disableLayer: {
    backgroundColor: Style.Color.White,
    height: '100%',
    width: '100%',
    position: 'absolute',
    opacity: 0.5,
    borderRadius: 4,
  },
  closeButton: {
    width: '100%',
    alignItems: 'flex-end',
    marginTop: 20,
    marginBottom: 4,
    minHeight: 24,
  },
});
