import React, {
  useContext, useState, Ref, useImperativeHandle, forwardRef,
} from 'react';
import {
  StyleSheet, View, Text, TouchableOpacity,
} from 'react-native';
import _ from 'lodash';
import { Hoverable } from 'react-native-web-hooks';

import { RootStoreContext } from '../../stores/RootStore';
import Style from '../../style';
import { Call, RefObject } from '../../types';
import ModuleTitle from '../ModuleTitle';
import { fetchCalls } from '../../api/Calls';
import { fetchUser } from '../../api/Users';
import LoadingIndicator from '../LoadingIndicator';
import I18n, { formatDate } from '../../i18n';
import ColSeparator from './ColSeparator';
import ModalView from '../ModalView';
import UserInfo from '../UserInfo';
import CallInfoModal from './CallInfoModal';
import CallMoveModal from './CallMoveModal';
import CallCancelModal from './CallCancelModal';
import UserAvatar from '../UserAvatar';
import ProgramName from '../ProgramName';
import { filterPastCalls, MIN_SQUEEZE_WIDTH, showCallStatusIcon } from '../../util/helpers';
import { BrowserInfo, getBrowserInfo } from '../HeaderBar';
import Section from '../Section';
import EmptyListHint from '../EmptyListHint';
import CallActionButtons, { ButtonOptions, SelectedOptions } from './CallActionButtons';
import { getCallStatusSymbol } from '../../util/coachings';

type CallTime = 'past' | 'upcoming';

const StatusLabel = ({ call }: { call: Call }) => {
  const statusSymbol = getCallStatusSymbol(call.status);
  return (
    <View style={styles.callStatusContainer}>
      <Style.Icon.Filled.ByName
        name={statusSymbol.icon}
        width={20}
        height={20}
        fill={Style.Color.Gray300}
      />
      <Text style={styles.textCallStatus}>{call.status}</Text>
    </View>
  );
};

const TopicItem = ({ call, browserInfo }: { call: Call, browserInfo: BrowserInfo; }) => {
  const store = useContext(RootStoreContext);
  const myId = store.auth.userId;
  const { browser, compactView } = browserInfo;

  const partnerId = call.clientId === myId ? call.coachId : call.clientId;
  // PartnerId should not be undefined, if it happens, show error
  const { data: partnerDetails, error } = fetchUser(partnerId || '').swr;
  if (!partnerDetails) return <EmptyListHint text={I18n.t('ui.calls.threeDots')} />;
  if ('httpStatus' in partnerDetails) {
    store.uiState.checkError(partnerDetails);
    return <EmptyListHint text={I18n.t('ui.calls.clientNotFound')} />;
  }
  if (error) return <EmptyListHint text={I18n.t('ui.calls.clientNotFound')} />;
  if (compactView) {
    return (
      <View
        key={`${call.id}-topic`}
        style={[
          styles.topic,
          compactView && styles.topicCompact,
          compactView && { width: browser.width - 10 },
        ]}
      >
        <View style={styles.sectionAvatar}>
          <UserAvatar user={partnerDetails} />
        </View>
        <View style={styles.topicName}>
          <View style={styles.moduleContainer}>
            <ModuleTitle
              key={call.moduleId}
              moduleId={call.moduleId}
              style={styles.textModule}
            />
            {showCallStatusIcon(call) && <StatusLabel call={call} />}
          </View>
          <Text style={styles.textTimeSlotsCompact}>{formatDate('date.appointmentDateTime', call.start)}</Text>
          <View style={styles.userNameContainerCompact}>
            <UserInfo
              user={partnerDetails}
              style={{ paddingVertical: 8 }}
            />
            <ProgramName
              userId={call.clientId}
              moduleKey={call.moduleKey}
              style={styles.programNameCompact}
            />
          </View>
        </View>
      </View>
    );
  }

  // For desktop view
  return (
    <View key={`${call.id}-topic`} style={styles.topic}>
      <View style={styles.sectionAvatar}>
        <UserAvatar user={partnerDetails} />
      </View>
      <View style={styles.topicName}>
        <ProgramName userId={call.clientId} moduleKey={call.moduleKey} />
        <UserInfo
          user={partnerDetails}
        />
      </View>
    </View>
  );
};

interface SCProps {
  callTime: CallTime;
  resetOtherComponent: (componentName: string) => void;
}

const ScheduledCalls = forwardRef(
  ({ callTime, resetOtherComponent }: SCProps, ref: Ref<RefObject>) => {
    useImperativeHandle(ref, () => ({ resetAllState }));
    const store = useContext(RootStoreContext);
    const myId = store.auth.userId;
    const browserInfo = getBrowserInfo();
    const { browser, compactView } = browserInfo;
    const [selectedCall, setSelectedCall] = useState<SelectedOptions>();
    const [selectedCallCell, setSelectedCallCell] = useState('');
    const { data: allCalls, error: errorCalls } = fetchCalls({ userId: myId }).swr;
    const fetchCallsUrl = fetchCalls({ userId: myId }).url;
    const isUpcoming = callTime === 'upcoming';

    let scheduledCalls: Call[];

    if (isUpcoming) {
      const pastCalls = _.reverse(filterPastCalls(allCalls));
      scheduledCalls = _.sortBy(
        _.differenceWith(_.filter(allCalls, { status: 'confirmed' }), pastCalls, _.isEqual.bind(_)),
        'start',
      );
    } else { // past calls
      scheduledCalls = _.reverse(filterPastCalls(allCalls));
    }

    if (errorCalls) store.uiState.setErrorMessage(errorCalls.message);

    const resetAllState = () => {
      setSelectedCall(undefined);
      setSelectedCallCell('');
    };

    const onActionButtonPress = (call: Call, activeButton: ButtonOptions) => {
      setSelectedCall({ call, activeButton });
      resetOtherComponent('ScheduledCalls');
    };

    const closeModal = () => {
      setSelectedCall(undefined);
    };

    const renderShowInfo = () => {
      if (!selectedCall || (selectedCall && selectedCall.activeButton !== 'Show Info')) return null;

      return (
        <ModalView
          isVisible={!!selectedCall}
          closeModal={closeModal}
          style={browser.height > 400 ? styles.modal : styles.modalWithScroll}
        >
          <CallInfoModal
            callId={selectedCall.call.id}
          />
        </ModalView>
      );
    };

    const renderMoveCall = () => {
      if (!selectedCall || (selectedCall && selectedCall.activeButton !== 'Move')) return null;
      if (!allCalls) return null;
      return (
        <ModalView
          isVisible={!!selectedCall}
          closeModal={closeModal}
          style={browser.height > 715 ? styles.modal : styles.modalWithScroll}
          showCloseBtn={false}
        >
          <CallMoveModal
            call={selectedCall.call}
            fetchCallsUrl={fetchCallsUrl}
            closeModal={closeModal}
          />
        </ModalView>
      );
    };

    const renderCancelCall = () => {
      if (!selectedCall
        || (selectedCall.activeButton
          && !['Reset', 'Obsolete'].includes(selectedCall.activeButton))
      ) return null;

      return (
        <ModalView
          isVisible={!!selectedCall}
          closeModal={closeModal}
          style={styles.modal}
          showCloseBtn={false}
        >
          <CallCancelModal
            call={selectedCall.call}
            fetchCallsUrl={fetchCallsUrl}
            closeModal={closeModal}
            isObsoleteSection={selectedCall.activeButton === 'Obsolete'}
          />
        </ModalView>
      );
    };

    const renderCurrentSchedule = (call: Call) => (
      <View style={styles.initialInfo}>
        <ColSeparator style={styles.separator} />
        <View>
          <View style={styles.moduleContainer}>
            <ModuleTitle
              key={call.moduleId}
              moduleId={call.moduleId}
              style={styles.textModule}
            />
            {showCallStatusIcon(call) && <StatusLabel call={call} />}
          </View>
          <Text numberOfLines={1} style={styles.textTimeSlots}>
            {formatDate('date.appointmentDayDateTime', call.start)}
          </Text>
        </View>
      </View>
    );

    const renderScheduledCallsList = () => {
      if (!scheduledCalls) return null;
      const calls = _.map(scheduledCalls, (call, index) => {
        const isActive = (selectedCall?.call.id === call.id);
        const isActiveCellCompact = (selectedCallCell === call.id);

        return (
          <Hoverable key={`${call.id}-cell`}>
            {(isHovered) => (
              <View key={call.id} testID={`ScheduledCalls.CallRow_${call.id}`}>
                <TouchableOpacity
                  disabled={!compactView}
                  onPress={() => {
                    if (!isActiveCellCompact) setSelectedCallCell(call.id);
                    else setSelectedCallCell('');
                    resetOtherComponent('ScheduledCalls');
                  }}
                  style={[
                    styles.item,
                    (isHovered && !compactView) && styles.itemHover,
                    (isActive || isActiveCellCompact) && styles.itemActive,
                    compactView && styles.itemCompact,
                  ]}
                >
                  <View style={styles.initialInfo}>
                    <TopicItem call={call} browserInfo={browserInfo} />
                    {!compactView && renderCurrentSchedule(call)}
                  </View>
                  {compactView && isActiveCellCompact && <ColSeparator compactView={compactView} />}
                  {((isHovered && !compactView) || isActive || isActiveCellCompact) && (
                    <CallActionButtons
                      call={call}
                      onPress={onActionButtonPress}
                      selectedCall={selectedCall}
                    />
                  )}
                </TouchableOpacity>
                {(index !== _.size(scheduledCalls) - 1) && <View style={styles.itemDivider} />}
              </View>
            )}
          </Hoverable>
        );
      });
      return (<View>{calls}</View>);
    };

    return (
      <Section
        icon={isUpcoming ? Style.Icon.Calendar : Style.Icon.Archive}
        title={isUpcoming ? I18n.t('ui.calls.upcomingCalls') : I18n.t('ui.calls.pastCalls')}
        key={isUpcoming ? 'upcomingCalls' : 'pastCalls'}
      >
        {renderShowInfo()}
        {renderMoveCall()}
        {renderCancelCall()}

        {(!allCalls) && <LoadingIndicator text={isUpcoming ? I18n.t('ui.calls.loadingUpcomingCalls') : I18n.t('ui.calls.loadingPastCalls')} />}
        {renderScheduledCallsList()}
        {(allCalls || errorCalls) && scheduledCalls.length === 0 && (
          <EmptyListHint text={isUpcoming ? I18n.t('ui.calls.noUpcomingCalls') : I18n.t('ui.calls.noPastCalls')} />
        )}
      </Section>
    );
  },
);

export default ScheduledCalls;

const styles = StyleSheet.create({
  item: {
    flexDirection: 'row',
    backgroundColor: Style.Color.White,
    justifyContent: 'space-between',
    borderLeftColor: Style.Color.Transparent,
    borderLeftWidth: 4,
    paddingLeft: 41,
    cursor: 'default',
  },
  itemCompact: {
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: 0,
    cursor: 'pointer',
  },
  itemHover: {
    borderLeftColor: Style.Color.Gray300,
  },
  itemActive: {
    borderLeftColor: Style.Color.Primary,
    borderLeftWidth: 4,
  },
  itemDivider: {
    borderBottomColor: Style.Color.Gray200,
    borderBottomWidth: 1,
    marginHorizontal: 28,
  },
  scheduleContainer: {
    backgroundColor: Style.Color.White,
    flexDirection: 'row',
    padding: 22,
    justifyContent: 'space-between',
    minHeight: 100,
  },
  initialInfo: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  topic: {
    flexDirection: 'row',
    minWidth: MIN_SQUEEZE_WIDTH - 20,
    minHeight: 90,
  },
  topicCompact: {
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 410,
    marginBottom: 16,
    paddingHorizontal: 20,
    paddingRight: 5,
  },
  sectionAvatar: {
    marginTop: 18,
  },
  topicName: {
    flex: 1,
    paddingLeft: 26,
    marginTop: 18,
  },
  textModule: {
    ...Style.Text.Caption,
    color: Style.Color.Black,
  },
  textTimeSlots: {
    ...Style.Text.Normal,
    color: Style.Color.Primary,
    marginTop: 10,
  },
  textTimeSlotsCompact: {
    ...Style.Text.Normal,
    color: Style.Color.Primary,
    marginTop: 4,
  },
  separator: {
    marginHorizontal: 28,
  },
  modal: {
    width: 424,
  },
  modalWithScroll: {
    width: 424,
    flex: 1,
  },
  userNameContainerCompact: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  programNameCompact: {
    ...Style.Text.Small,
    color: Style.Color.Gray400,
    marginLeft: 12,
  },
  moduleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  callStatusContainer: {
    backgroundColor: Style.Color.Gray200,
    paddingHorizontal: 8,
    paddingVertical: 4,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
    marginLeft: 12,
    flexDirection: 'row',
  },
  textCallStatus: {
    ...Style.Text.Normal,
    color: Style.Color.Gray600,
    marginLeft: 4,
  },
});
