import React, { useContext, useEffect, useRef, useState } from 'react';
import { ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import _ from 'lodash';
import { Hoverable } from 'react-native-web-hooks';
import * as Progress from 'react-native-progress';

import { RootStoreContext } from '../../stores/RootStore';
import Style from '../../style';
import I18n, { formatDate } from '../../i18n';
import Section from '../Section';
import LoadingIndicator from '../LoadingIndicator';
import { Coaching } from '../../types';
import { MIN_SQUEEZE_WIDTH, isCloseToBottom } from '../../util/helpers';
import EmptyListHint from '../EmptyListHint';
import { fetchCoachings } from '../../api/Coachings';
import { BrowserInfo, getBrowserInfo } from '../HeaderBar';
import UserAvatar from '../UserAvatar';
import { fetchUser } from '../../api/Users';
import UserInfo from '../UserInfo';
import { fetchProgramByKey } from '../../api/Programs';
import CoachingStats from './CoachingStats';
import SearchBox from '../SearchBox';

type CoachingStatus = 'active' | 'finished';
type ButtonOptions = 'Show Invoice'; // Will need it in future
type SelectedOptions = {
  coaching: Coaching,
  activeButton?: ButtonOptions,
};

const LIMIT = 10;

const renderProgressBar = (coaching: Coaching, compactView: boolean) => {
  const progress = coaching.stats?.progress;
  return (
    <View style={[styles.progressBarSec, compactView && styles.progressBarSecCompact]}>
      <Progress.Bar
        progress={progress}
        width={80}
        unfilledColor={Style.Color.Gray200}
        borderWidth={0}
        color={Style.Color.Primary}
      />
      <Text style={styles.complete} numberOfLines={1}>
        {I18n.t('ui.coachings.percentCompleted', { percent: Math.floor((progress || 0) * 100) })}
      </Text>
      <Text style={compactView ? styles.complete : styles.textTimeSlotsCompact}>
        {`${formatDate('date.monthYearShort', coaching.start)} - ${formatDate('date.monthYearShort', coaching.end)}`}
      </Text>
    </View>
  );
};

const TopicItem = ({ coaching, browserInfo }: { coaching: Coaching, browserInfo: BrowserInfo }) => {
  const store = useContext(RootStoreContext);
  const { compactView } = browserInfo;
  const { data: client, error } = fetchUser(coaching.clientId).swr;
  const { data: program } = fetchProgramByKey(coaching.programKey).swr;
  if (!client) return <EmptyListHint text={I18n.t('ui.calls.threeDots')} />;
  if ('httpStatus' in client) {
    store.uiState.checkError(client);
    return <EmptyListHint text={I18n.t('ui.calls.clientNotFound')} />;
  }
  if (error) return <EmptyListHint text={I18n.t('ui.calls.clientNotFound')} />;

  return (
    <View style={styles.topic}>
      <View style={styles.sectionAvatar}>
        <UserAvatar user={client} />
      </View>
      <View style={styles.topicName}>
        <UserInfo
          user={client}
          style={styles.userInfo}
          nameStyle={styles.topicTitle}
        />
        <Text numberOfLines={1} style={styles.programName}>{program?.name}</Text>
        {compactView && renderProgressBar(coaching, compactView)}
      </View>
    </View>
  );
};

interface CSProps {
  status: CoachingStatus;
}

const CoachingsSummary = ({ status }: CSProps) => {
  const store = useContext(RootStoreContext);
  const myId = store.auth.userId;
  if (!myId) return null;
  const [page, setPage] = useState(0);
  const [selectedCoaching, setSelectedCoaching] = useState<SelectedOptions>();
  const [clientCode, setClientCode] = useState('');
  // Will be required when we will add invoice button here for compact view.
  const [selectedCoachingCell, setSelectedCoachingCell] = useState('');
  const allCoachings = useRef<Coaching[]>([]);
  const browserInfo = getBrowserInfo();
  const { compactView } = browserInfo;
  const isOngoing = status === 'active';
  const query = {
    coachId: myId,
    $sort: '-created',
    $offset: page,
    $limit: LIMIT,
    $view: 'full',
    status: isOngoing ? 'active' : ['finished', 'aborted'],
  };

  const { data: allCoachingsForPage, error: errAllCoachings } = fetchCoachings({
    ...query,
    ...(clientCode !== '' && { clientCode }),
  }).swr;

  if (allCoachingsForPage && 'httpStatus' in allCoachingsForPage) {
    const isAuthFail = store.uiState.checkError(allCoachingsForPage);
    if (isAuthFail) return null;
  }

  if (allCoachingsForPage) allCoachings.current = _.unionBy(allCoachings.current, allCoachingsForPage, 'id');

  const scheduledCoachings = allCoachings.current;

  const sectionTitle = I18n.t(`ui.coachings.${status === 'active' ? 'ongoingCoachings' : 'finishedCoachings'}`);

  useEffect(() => {
    if ((!scheduledCoachings || scheduledCoachings.length < LIMIT)
      && allCoachingsForPage?.length === LIMIT && clientCode === '') {
      setPage(allCoachings.current.length);
    }
  }, [allCoachingsForPage]);

  const setSelectedCoachingFromChild = (coaching?: Coaching) => {
    setSelectedCoaching(coaching ? { coaching } : undefined);
    toggleActiveCell(coaching?.id || '', false);
  };

  const toggleActiveCell = (coachingId: string, isActiveCellCompact: boolean) => {
    if (!isActiveCellCompact) setSelectedCoachingCell(coachingId);
    else setSelectedCoachingCell('');
  };

  const setSearchBoxState = (text: string) => {
    allCoachings.current = [];
    setClientCode(text);
    setPage(0);
  };

  const renderSearch = () => (
    <SearchBox
      setSearchBoxState={setSearchBoxState}
      placeholder={I18n.t('ui.coachings.searchPlaceholder')}
      minChars={2}
    />
  );

  const renderStatus = (coaching: Coaching) => (
    <View style={styles.initialInfo}>
      <View style={[styles.verticalDivider, compactView && styles.dividerCompact]} />
      <View>
        {renderProgressBar(coaching, compactView)}
      </View>
      <View style={[styles.verticalDivider, compactView && styles.dividerCompact]} />
    </View>
  );

  const renderCoachingStats = (coaching: Coaching) => (
    <View style={[styles.coachingStats, compactView && styles.CoachingStatsCompact]}>
      <CoachingStats
        coachingDetails={coaching}
        setSelectedCoachingFromChild={setSelectedCoachingFromChild}
        showTooltipTitle
      />
    </View>
  );

  const renderCoachingsList = () => {
    if (!scheduledCoachings) return null;
    const coachings = _.map(scheduledCoachings, (coaching, index) => {
      const isActive = (selectedCoaching?.coaching.id === coaching.id);
      const isActiveCellCompact = (selectedCoachingCell === coaching.id);

      return (
        <Hoverable key={`${coaching.id}-cell`}>
          {(isHovered) => (
            <View key={coaching.id}>
              <TouchableOpacity
                disabled={!compactView}
                onPress={() => toggleActiveCell(coaching.id, isActiveCellCompact)}
                style={[
                  styles.item,
                  (isHovered && !compactView) && styles.itemHover,
                  (isActive || isActiveCellCompact) && styles.itemActive,
                  compactView && styles.itemCompact,
                ]}
              >
                <View style={styles.initialInfo}>
                  <TopicItem coaching={coaching} browserInfo={browserInfo} />
                  {!compactView && renderStatus(coaching)}
                </View>
                {renderCoachingStats(coaching)}
              </TouchableOpacity>
              {(index !== _.size(scheduledCoachings) - 1) && <View style={styles.itemDivider} />}
            </View>
          )}
        </Hoverable>
      );
    });
    return (<View>{coachings}</View>);
  };

  return (
    <Section
      icon={isOngoing ? Style.Icon.ClockClockwise : Style.Icon.CheckCircle}
      title={sectionTitle}
      key={sectionTitle}
      titleRightComponent={renderSearch()}
    >
      {scheduledCoachings.length > 0 && (
        <View style={styles.coachingsContainer}>
          <ScrollView
            showsVerticalScrollIndicator={false}
            style={{ marginTop: 6 }}
            scrollEventThrottle={16}
            onScroll={(e) => {
              if (isCloseToBottom(e.nativeEvent)) {
                setPage(allCoachings.current.length);
              }
            }}
          >
            {renderCoachingsList()}
          </ScrollView>
        </View>
      )}
      <View style={{ height: (allCoachingsForPage && scheduledCoachings.length === 0) ? 48 : 30 }}>
        {!(allCoachingsForPage || errAllCoachings) && <LoadingIndicator text={I18n.t('ui.coachings.loadingCoachings')} />}
        {(allCoachingsForPage || errAllCoachings) && scheduledCoachings.length === 0 && (
          <EmptyListHint text={I18n.t(`ui.coachings.emptyListHint.${status}`)} />
        )}
      </View>
    </Section>
  );
};

export default CoachingsSummary;

const styles = StyleSheet.create({
  complete: {
    ...Style.Text.Small,
    color: Style.Color.Gray400,
    marginTop: 8,
  },
  coachingsContainer: {
    maxHeight: 500,
  },
  item: {
    flexDirection: 'row',
    backgroundColor: Style.Color.White,
    borderLeftColor: Style.Color.Transparent,
    borderLeftWidth: 4,
    paddingLeft: 41,
    cursor: 'default',
  },
  itemCompact: {
    flexDirection: 'column',
    paddingLeft: 40,
    cursor: 'pointer',
    paddingBottom: 10,
  },
  itemHover: {
    borderLeftColor: Style.Color.Gray300,
  },
  itemActive: {
    borderLeftColor: Style.Color.Primary,
    borderLeftWidth: 4,
  },
  itemDivider: {
    borderBottomColor: Style.Color.Gray200,
    borderBottomWidth: 1,
    marginHorizontal: 28,
  },
  initialInfo: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  topic: {
    flexDirection: 'row',
    minWidth: MIN_SQUEEZE_WIDTH - 20,
    minHeight: 90,
  },
  sectionAvatar: {
    marginTop: 18,
  },
  topicName: {
    flex: 1,
    paddingLeft: 26,
    marginTop: 18,
  },
  textTimeSlotsCompact: {
    ...Style.Text.Normal,
    color: Style.Color.Primary,
    marginTop: 4,
  },
  coachingStats: {
    flex: 1,
    justifyContent: 'center',
  },
  CoachingStatsCompact: {
    paddingLeft: 50,
  },
  progressBarSec: {
    width: 120,
  },
  progressBarSecCompact: {
    marginTop: 10,
    marginBottom: 20,
  },
  verticalDivider: {
    borderLeftColor: Style.Color.Gray200,
    borderLeftWidth: 1,
    height: 50,
    marginHorizontal: 20,
  },
  dividerCompact: {
    top: 8,
    height: 64,
  },
  userInfo: {
    paddingVertical: 5,
  },
  topicTitle: {
    ...Style.Text.Caption,
    color: Style.Color.Black,
  },
  programName: {
    ...Style.Text.Normal,
    color: Style.Color.Black,
    maxWidth: 220,
  },
});
