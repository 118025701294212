export default {
  ui: {
    login: {
      forgotPassword: 'Forgot password?',
      welcome: 'Welcome',
      email: 'Email',
      password: 'Password',
      signIn: 'Sign in',
    },
    home: {
      callsSummary: {
        title: 'Calls',
        total: 'Total',
        upcoming: 'Upcoming',
        last90Days: 'Last 90 days',
        noShows: 'No shows',
      },
      clientsSummary: {
        title: 'Clients',
        allPrograms: 'All Programs',
        leadership: 'Leadership',
        growth: 'Growth',
        transformation: 'Transformation',
      },
      qualitySummary: {
        title: 'Quality',
        nps: 'NPS',
        goalAchievement: 'Goal achievement',
        coachScore: 'Coach score',
        supervisionRate: 'Supervision rate',
      },
      recentProjects: {
        title: 'Recent Projects',
        preparation: 'in preparation',
        complete: '%{percent}% completed',
        status: 'Status',
        clients: {
          one: '%{count} Client',
          other: '%{count} Clients',
        },
      },
    },
    calls: {
      // Call requests
      callRequests: 'Call Requests',
      loadingCalls: 'Loading calls...',
      noPendingCallReq: 'You have no pending requests.',
      suggested: 'suggested ',
      nTimeslots: {
        one: '%{count} timeslot',
        other: '%{count} timeslots',
      },

      // Metered Calls
      meteredCalls: 'Ad-hoc Calls',
      loadingMeteredCalls: 'Loading ad-hoc calls...',
      nAvailableMins: '%{mins} mins available',
      nCallDuration: '%{mins} mins',
      expiresOn: 'Expires on %{date}',

      // Calls to schedule
      callsToSchedule: 'Calls to Schedule',
      loadingCallsToSchedule: 'Loading calls to schedule...',
      noCallsToSchedule: 'You have no calls to schedule.',
      nCallsToSchedule: {
        one: '%{count} call to schedule',
        other: '%{count} calls to schedule',
      },
      clientNotFound: 'Client not found',
      scheduleCallSuccess: 'Call scheduled, emails sent',

      // Scheduled calls
      upcomingCalls: 'Upcoming Calls',
      pastCalls: 'Past Calls',
      loadingUpcomingCalls: 'Loading upcoming calls...',
      loadingPastCalls: 'Loading past calls...',
      noUpcomingCalls: 'You have no upcoming calls.',
      noPastCalls: 'You do not have any past calls yet.',

      // Call Info Modal
      moved: 'moved',
      nMinutes: {
        one: '%{count} min',
        other: '%{count} mins',
      },
      actualDuration: '(actual duration)',
      noShow: 'No show',
      noDialIn: 'No dial-in',
      pleaseReschedule: '(Please reschedule)',
      contactSupport: '(Contact support)',
      tooShort: 'Call was too short',
      coachNoShow: 'No show (coach)',

      // Call move modal
      shortExplanation: 'Short explanation',
      moveCallSuccess: 'Call moved, emails sent',
      moveCall: 'Move Call',
      moveCallWarning: 'Please only move a call once you have agreed on the new time with your client. After you click on “Confirm” emails will be sent out to all participants.',
      clientRequest: 'Requested by client',
      other: 'Other',
      clientNoShow: 'No show',
      techIssues: 'Technical Problems',

      // Call cancel modal
      resetCall: 'Reset Call',
      resetCallWarning: 'A call should only be reset in extraordinary situations (sickness, emergencies). After you click on “Reset” the call will be reset to “Unscheduled” and emails will be sent out to all participants.',
      resetCallSuccess: 'Call reset, emails sent',
      reason: 'Reason',
      resetReasonPlaceholder: 'Why is this call reset?',
      threeDots: '...',

      discardCall: 'Discard Call',
      discardCallDetails: 'If you don\'t want to reschedule the call, you can permanently discard it.',
      discardCallWarning: 'Discarding the call is final, you won\'t be able to reschedule it later. Are you sure you want to discard this call?',
      discardCallSuccess: 'Call discarded, emails sent',
      discardReasonPlaceholder: 'Why is this call permanently discarded?',

      event: {
        Reset: 'Reset',
        Move: 'Rescheduled',
        Fail: 'Failed',
        Obsolete: 'Discarded',
        Schedule: 'Scheduled',
      },
    },
    availabilities: {
      // Weekly avail picker
      timePreferencesTitle: 'Time Preferences for Supervision',
      timePreferencesDes: 'Supervision calls are requested “ad hoc” from all supervisors. Let us know during which hours you are generally available for supervision. These times will apply recurringly, week after week. If you go on a longer vacation or move to another time zone, please adapt your preferred hours accordingly.',
      timePreferencesSaved: 'Time preferences saved',
      loadingTimePreferences: 'Loading time preferences...',
      from: 'from',
      to: 'to',
      weekDays: {
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        sunday: 'Sunday',
      },

      // Daily avail picker
      cw: 'CW',
      availabilitiesCoachingTitle: 'Availabilities for Coaching',
      availabilitiesCoachingDes: 'Highlight all 30-min blocks during which you are available for a coaching session in the next 14 days. Once you are done, press “Save”.',
      availabilitiesSaved: 'Availabilities saved',
    },
    buttons: {
      save: 'Save',
      cancel: 'Cancel',
      confirm: 'Confirm',
      showInfo: 'Show Info',
      move: 'Move',
      discard: 'Discard',
      reset: 'Reset',
      logOut: 'Log out',
    },
    projects: {
      clients: {
        one: '%{count} Client',
        other: '%{count} Clients',
      },
      program: 'Program:',
      inPreparation: 'in preparation',
      percentCompleted: '%{percent}% completed',
      status: 'Status',
      ongoingProjects: 'Ongoing Projects',
      finishedProjects: 'Finished Projects',
      loadingProjects: 'Loading projects...',
      noOngoingProjects: 'You have no ongoing projects.',
      noFinishedProjects: 'You have no finished projects.',
      backToProjects: 'Back to projects',
      months: {
        one: '%{count} month',
        other: '%{count} months',
      },
      calls: {
        title: 'Calls',
        total: 'Total',
        completed: 'Completed',
        upcoming: 'Upcoming',
        placeholder: 'Placeholder',
        noShows: 'No shows',
        finishedPercentage: '%{percent}% finished',
      },
      overview: {
        title: 'Overview',
        participants: 'Participants',
        coaches: 'Coaches',
        timeline: 'Timeline',
        duration: 'Duration',
        elapsed: '%{percent}% elapsed',
      },
      engagement: {
        title: 'Engagement',
        chatUsage: 'Chat usage (msgs/wk)',
        callMoveRate: 'Call move rate',
        moduleCompletion: 'Module completion',
        actionPlanUsage: 'Action plan usage',
        overall: '%{percent}% overall',
      },
      actions: {
        title: 'Actions',
        inviteNewParticipant: 'Invite new participant',
        generateReport: 'Generate report',
      },
      participants: {
        title: 'Participants',
        searchPlaceholder: 'First name or code',
        filterByCohort: 'Filter by cohort:',
        firstName: 'First Name',
        code: 'Code',
        coach: 'Coach',
        invited: 'Invited',
        signedUp: 'Signed up',
      },
    },
    coachings: {
      ongoingCoachings: 'Ongoing Coachings',
      finishedCoachings: 'Finished Coachings',
      loadingCoachings: 'Loading coachings...',
      percentCompleted: '%{percent}% completed',
      emptyListHint: {
        active: 'You have no ongoing coachings.',
        finished: 'You have no finished coachings.',
      },
      searchPlaceholder: 'Code',
    },
  }, // ui End
  screen: {
    Dashboards: 'Dashboards',
    Home: 'Home',

    Coaching: 'Coaching',
    Calls: 'Calls',
    Availabilities: 'Availabilities',
    Coachings: 'Coachings',
    NotFound: 'Page does not exist.',

    Resources: 'Resources',
    Projects: 'Projects',
  },
  error: {
    unknownError: 'Something went wrong',
    urlNotExist: 'The requested page does not exist.',
    noUserAccess: 'You are not authorized to view this page.',
    minReqChars: 'Type at least %{min} characters',
    api: {
      ADMIN_ONLY: 'Requires administrator privileges',
      AUTH_FAILED: 'Unknown email address or incorrect password.',
      AUTH_EXPIRED_TOKEN: 'Authentication token expired',
      AUTH_INVALID_TOKEN: 'Authentication token is invalid',
      AUTH_MISSING_TOKEN: 'Authentication token missing',
      AUTH_MISSING_ROLE: 'Access denied (insufficient privileges)',
      BAD_REQUEST: 'Invalid request parameters',
      INCONSISTENT_PARAMS: 'Inconsistent request parameters',
      INVALID_AVAIL: 'No free availability for this user/time',
      INVALID_MODULE: 'Not a valid module',
      TOO_MANY_REQUESTS: 'API request rate limit exceeded',
      INVALID_TIME: 'Selected time is not valid',
    },
    login: {
      pleaseEnterValidEmail: 'Please enter a valid email.',
      passwordLengthWarning: 'Between 8 and 72 characters, please.',
    },
  },
  warning: {
    timezonePart1: 'The times below are specified in the',
    timezonePart2: 'time zone. You can change your time zone preference in the app settings.',
  },
  date: {
    appointmentDate: 'dd, D MMM Y',
    appointmentDateTime: 'DD MMM Y, h:mma',
    appointmentDayDateTime: 'dd, DD MMM Y, h:mma',
    dateRel: {
      sameDay: '[today]',
      lastDay: '[yesterday]',
      lastWeek: 'MMMM D, Y',
      sameElse: 'MMMM D, Y',
    },
    dateShort: 'DD MMMM',
    dateTimeRel: {
      sameDay: '[today at] h.mma',
      lastDay: '[yesterday at] h.mma',
      lastWeek: 'ddd h.mma',
      sameElse: 'MM/DD/YYYY [at] h.mma',
    },
    dateTimeRelShort: {
      sameDay: 'h.mma',
      lastDay: '[Yesterday]',
      lastWeek: 'M/D/YY',
      sameElse: 'M/D/YY',
    },
    dayMonthShort: 'D MMM',
    monthYearShort: 'MM/\'YY',
    dayMonthYearShort: 'D MMM Y',
    dayShort: 'dd',
    fullDate: 'MM/DD/YYYY',
    fullDateMonthName: 'D MMMM Y',
    fullDateTime: 'LLLL',
    monthNameYear: 'MMMM Y',
    monthShort: 'MMM',
    shortDateTime: 'llll',
    time: 'h.mma',
    timeColon: 'h:mma',
    timeExpl: 'h.mma',
    timeSpan: '%{from} - %{to}',
    timeSpanShort: '%{from} - %{to}',
    formatedTime: '%{time}',
  },
  style: {
    scheduledCalls: {
      buttonMarginH: 30,
    },
  },
  other: {
    callStatuses: {
      placeholder: 'Not scheduled yet',
      pending: 'Pending',
      confirmed: 'Upcoming',
      canceled: 'Canceled',
      finished: 'Completed',
      failed: 'Failed',
      obsolete: 'Obsoleted',
    },
    callStatusWithDate: {
      placeholder: 'Not scheduled yet',
      pending: 'Pending',
      confirmed: 'Upcoming on %{date}',
      canceled: 'Canceled on %{date}',
      finished: 'Completed on %{date}',
      failed: 'Failed on %{date}',
      obsolete: 'Obsoleted on %{date}',
    },
    completedOn: 'Completed on',
    notCompletedYet: 'Not completed yet',
  },
};
