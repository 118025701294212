import Constants from 'expo-constants';
import qs from 'qs';
import _ from 'lodash';
import { cache } from 'swr';

import { autorun } from 'mobx';
import type { ManifestExtra } from '../types/expo-constants';
import { ErrorRes } from '../types';
import { rootStore } from '../stores/RootStore';
import I18n from '../i18n';

const { API_URL } = Constants.manifest?.extra as ManifestExtra;

let xsrfToken: string;
autorun(() => {
  xsrfToken = rootStore.auth.xsrfToken;
});

const QS_OPTIONS = {
  sort: (a: string, b: string) => { if (a > b) return 1; return 0; },
  indices: false,
};

type MethodType = 'POST' | 'PUT' | 'PATCH' | 'DELETE';

const getDefaultHeader = () => (
  {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-XSRF-Token': xsrfToken,
  }
);

const assembleUrl = (path: string, query = {}) => {
  const queryStr = _.isEmpty(query) ? '' : `?${qs.stringify(query, QS_OPTIONS)}`;
  return `${API_URL}${path}${queryStr}`;
};

const prepareBody = (body = {}) => {
  if (body instanceof Array) return JSON.stringify(body);

  const isBodyEmpty = _.isEmpty(body);
  if (!isBodyEmpty) return JSON.stringify(body);
  return null;
};

export const createFetcher = (
  endpoint: string,
  query = {},
) => {
  const url = assembleUrl(endpoint, query);
  const fetcher = () => fetch(url, {
    headers: getDefaultHeader(),
    credentials: 'include',
  }).then((r) => r.json());

  return { url, fetcher };
};

export const fetchWithoutSWRHook = async <T>(
  endpoint: string,
  query = {},
  forceReload = false,
): Promise<T> => {
  const url = assembleUrl(endpoint, query);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const cachedData = cache.get(url);
  if (cachedData && !forceReload) {
    return cachedData as T;
  }

  const res = await fetch(url, {
    method: 'GET',
    headers: getDefaultHeader(),
    credentials: 'include',
  });
  if (res.status >= 400) {
    const jsonErr = await res.json() as ErrorRes;
    const errMsg = jsonErr.code ? I18n.t(`error.api.${jsonErr.code}`) : I18n.t('error.unknownError');
    throw new Error(errMsg);
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const json = await res.json();
  cache.set(url, json);
  return json as T;
};

export async function sendToServer<T>(
  method: MethodType,
  endpoint: string,
  query = {},
  body = {},
): Promise<T> {
  const endPoint = assembleUrl(endpoint, query);
  const res = await fetch(endPoint, {
    method,
    headers: getDefaultHeader(),
    credentials: 'include',
    body: prepareBody(body),
  });

  if (res.status >= 400) {
    const jsonErr = await res.json() as ErrorRes;
    const errMsg = jsonErr.code ? I18n.t(`error.api.${jsonErr.code}`) : I18n.t('error.unknownError');
    throw new Error(errMsg);
  }

  const json = await res.json() as T;

  return json;
}
