import React, { useContext, useEffect, useState } from 'react';
import {
  StyleSheet, View, Image, TouchableOpacity, ImageSourcePropType, Text,
} from 'react-native';
import { Hoverable } from 'react-native-web-hooks';
import { isMobile } from 'react-device-detect';
import moment from 'moment';

import Style from '../style';
import { IconName } from '../style/IconsRegular';
import { RootStoreContext } from '../stores/RootStore';
import { MIN_INNER_WIDTH, MIN_SQUEEZE_WIDTH } from '../util/helpers';
import opsIcon from '../../assets/icon.png';
import { User } from '../types';
import UserAvatar from './UserAvatar';
import DropdownMenu from './DropdownMenu';
import I18n from '../i18n';

export type BrowserInfo = {
  browser : {
    width: number;
    height: number;
  };
  compactView: boolean;
};

export const getBrowserInfo = () => {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);
  useEffect(() => {
    const onChange = () => {
      setInnerWidth(window.innerWidth);
      setInnerHeight(window.innerHeight);
    };
    window.addEventListener('resize', onChange);
  });
  const compactView = isMobile || innerWidth < MIN_INNER_WIDTH;
  const browser = { width: innerWidth, height: innerHeight };
  const browserInfo: BrowserInfo = { browser, compactView };
  return browserInfo;
};
let isHoverOnButton = false;

type HBProps = {
  iconName?: IconName, imageSource?: ImageSourcePropType,
  onPress: () => void, compactView?: boolean,
  user?: User,
  isActive?: boolean,
};

const HeaderButton = (
  {
    iconName, imageSource, onPress, compactView = false, user, isActive = false,
  }: HBProps,
) => (
  <Hoverable>
    {(isHovered) => {
      isHoverOnButton = isHovered;
      return (
        <TouchableOpacity
          accessible
          style={[
            styles.iconBox,
            (isHovered || isActive) && { backgroundColor: Style.Color.Gray200 },
            compactView && styles.iconBoxCompactView,
          ]}
          onPress={() => onPress()}
        >
          {iconName && (
            <Style.Icon.ByName name={iconName} width={24} height={24} fill={Style.Color.Black} />
          )}
          {(!iconName && imageSource) && <Image source={imageSource} style={styles.avatar} />}
          {(!iconName && user) && <UserAvatar user={user} />}
        </TouchableOpacity>
      );
    }}
  </Hoverable>
);

interface HeaderBarProps {
  sideMenuToggle: () => void;
  myDetails: User | undefined;
}

const HeaderBar = (props: HeaderBarProps) => {
  const store = useContext(RootStoreContext);
  const { myDetails } = props;
  const isMenubarOpen = store.uiState.showSideBar;
  const { compactView } = getBrowserInfo();
  const pressLogout = async () => {
    store.uiState.setErrorMessage('');
    await store.auth.logOut();
  };

  // Get timezone from user profile instead of device timezone
  if (myDetails?.timezone) moment.tz.setDefault(myDetails?.timezone);

  const [activeDropdown, setActiveDropdown] = useState('none');
  const setDropdown = (key: string) => {
    if (activeDropdown === key) setActiveDropdown('none');
    else setActiveDropdown(key);
    if (store.uiState.showSideBar && compactView) props.sideMenuToggle();
  };
  const closeDropdownOnClickOutside = () => {
    if (!isHoverOnButton) setActiveDropdown('none');
  };

  return (
    <View style={[
      styles.container,
      compactView && { zIndex: 4 },
      isMenubarOpen && compactView && styles.noBottomBorder,
    ]}
    >
      {compactView && (
        <TouchableOpacity onPress={() => props.sideMenuToggle()} style={styles.hamburgerIcon}>
          <Style.Icon.Union
            width={24}
            height={24}
            fill={Style.Color.Gray800}
          />
        </TouchableOpacity>
      )}
      {compactView && (
        <View style={styles.logoContainer}>
          <View style={styles.logo}>
            <Image source={opsIcon} style={styles.logoIcon} />
          </View>
          <Text numberOfLines={1} style={styles.logoText}>Ops Center</Text>
        </View>
      )}
      <View style={styles.iconContainer}>
        {/* {!compactView && <HeaderButton iconName="Sun" onPress={pressNoAction} />}
        {!compactView && <HeaderButton iconName="Gear" onPress={pressNoAction} />} */}
        <HeaderButton user={myDetails} onPress={() => setDropdown('avatar')} isActive={activeDropdown === 'avatar'} compactView={compactView} />
      </View>
      <DropdownMenu
        key="avatar"
        items={[
          { label: myDetails?.firstName || '', type: 'text' },
          { type: 'divider' },
          { label: I18n.t('ui.buttons.logOut'), onPress: () => pressLogout(), type: 'button' },
        ]}
        isVisible={activeDropdown === 'avatar'}
        style={{ right: 2 }}
        onClickOutside={() => { closeDropdownOnClickOutside(); }}
      />
    </View>
  );
};

export default HeaderBar;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'row',
    backgroundColor: Style.Color.White,
    height: 72,
    top: 0,
    position: 'absolute',
    minWidth: MIN_SQUEEZE_WIDTH,
    borderBottomColor: Style.Color.Gray100,
    borderBottomWidth: 1,
    zIndex: 2,
  },
  noBottomBorder: {
    borderBottomWidth: 0,
  },
  iconContainer: {
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    position: 'absolute',
    right: 0,
    cursor: 'pointer',
  },
  iconBox: {
    height: 72,
    width: 72,
    borderLeftColor: Style.Color.Gray200,
    borderLeftWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  iconBoxCompactView: {
    borderLeftWidth: 0,
  },
  avatar: {
    width: 48,
    height: 48,
  },
  logoContainer: {
    flexDirection: 'row',
    paddingVertical: 18,
    justifyContent: 'flex-start',
    alignItems: 'center',
    minHeight: 72,
  },
  logo: {
    width: 38,
    height: 38,
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoIcon: {
    width: 37,
    height: 37,
  },
  logoText: {
    ...Style.Text.Heading2,
    marginLeft: 16,
  },
  hamburgerIcon: {
    position: 'absolute',
    left: 20,
    top: 24,
  },
});
