import React from 'react';
import {
  View, StyleSheet, ViewStyle, ScrollView,
} from 'react-native';
import Modal from 'react-native-modal';
import Style from '../style';
import { MIN_SQUEEZE_WIDTH } from '../util/helpers';
import ButtonSmall from './ButtonSmall';
import { getBrowserInfo } from './HeaderBar';

interface ModalViewProps {
  isVisible: boolean;
  closeModal: () => void;
  children?: React.ReactNode;
  style?: ViewStyle;
  showCloseBtn?: boolean;
}

const ModalView = ({
  children, isVisible, closeModal, style = {},
  showCloseBtn = true,
}: ModalViewProps) => {
  const { browser, compactView } = getBrowserInfo();
  return (
    <Modal
      animationIn="slideInUp"
      isVisible={isVisible}
      backdropOpacity={0.5}
      deviceWidth={browser.width}
      deviceHeight={browser.height}
      backdropColor={Style.Color.Black}
      onBackdropPress={closeModal}
    >
      <View style={[
        styles.container,
        compactView && { maxWidth: browser.width - 16 },
        style,
        { maxHeight: browser.height - 50 },
      ]}
      >
        {showCloseBtn && (
          <View style={styles.closeButton}>
            <ButtonSmall
              icon="X"
              onPress={closeModal}
              iconSize={24}
              testId="ModalView.CloseModalButton"
            />
          </View>
        )}
        <ScrollView showsHorizontalScrollIndicator={false}>
          {children}
        </ScrollView>
      </View>
    </Modal>
  );
};

export default ModalView;

const styles = StyleSheet.create({
  container: {
    borderRadius: 4,
    backgroundColor: 'white',
    alignSelf: 'center',
    minWidth: MIN_SQUEEZE_WIDTH,
    minHeight: 300,
    marginHorizontal: 8,
  },
  closeButton: {
    width: '100%',
    alignItems: 'flex-end',
    marginTop: 20,
    marginBottom: 4,
  },
});
