export default {
  ui: {
    login: {
      forgotPassword: 'Passwort vergessen?',
      welcome: 'Willkommen',
      email: 'E-Mail',
      password: 'Passwort',
      signIn: 'Anmelden',
    },
    home: {
      callsSummary: {
        title: 'Gespräche',
        total: 'Gesamt',
        upcoming: 'Demnächst',
        last90Days: 'Letzte 90 Tage',
        noShows: 'No-shows',
      },
      clientsSummary: {
        title: 'Klienten',
        allPrograms: 'Alle Programme',
        leadership: 'Leadership',
        growth: 'Growth',
        transformation: 'Transformation',
      },
      qualitySummary: {
        title: 'Qualität',
        nps: 'NPS',
        goalAchievement: 'Zielerreichung',
        coachScore: 'Coach-Bewertung',
        supervisionRate: 'Supervisionsquote',
      },
      recentProjects: {
        title: 'Letzte Projekte',
        preparation: 'in Vorbereitung',
        complete: '%{percent}% abgeschlossen',
        status: 'Status',
        clients: {
          one: '%{count} Kunde',
          other: '%{count} Kunden',
        },
      },
    },
    calls: {
      // Call requests
      callRequests: 'Anfragen',
      loadingCalls: 'Gespräche werden geladen...',
      noPendingCallReq: 'Du hast keine offenen Anfragen.',
      suggested: '',
      nTimeslots: {
        one: 'Ein Terminvorschlag von Dir',
        other: '%{count} Terminvorschläge von Dir',
      },

      // Metered Calls
      meteredCalls: 'Ad-hoc-Gespräche',
      loadingMeteredCalls: 'Ad-hoc-Gespräche laden...',
      nAvailableMins: '%{mins} Minuten verfügbar',
      nCallDuration: '%{mins} Minuten',
      expiresOn: 'Läuft ab am %{date}',

      // Calls to schedule
      callsToSchedule: 'Zu vereinbarende Gespräche',
      loadingCallsToSchedule: 'Zu vereinbarende Gespräche werden geladen...',
      noCallsToSchedule: 'Du hast keine zu vereinbarenden Gespräche.',
      nCallsToSchedule: {
        one: 'Ein Gespräch zu vereinbaren',
        other: '%{count} Gespräche zu vereinbaren',
      },
      clientNotFound: 'Klient nicht gefunden',
      scheduleCallSuccess: 'Gespräch geplant, E-Mails gesendet.',

      // Scheduled calls
      upcomingCalls: 'Anstehende Gespräche',
      pastCalls: 'Vergangene Gespräche',
      loadingUpcomingCalls: 'Anstehende Gespräche werden geladen...',
      loadingPastCalls: 'Vergangene Gespräche werden geladen...',
      noUpcomingCalls: 'Du hast keine anstehenden Gespräche.',
      noPastCalls: 'Du hast noch keine vergangenen Gespräche.',

      // Call Info Modal
      moved: 'verschoben',
      nMinutes: {
        one: '%{count} Minute',
        other: '%{count} Minuten',
      },
      actualDuration: '(tatsächliche Dauer)',
      noShow: 'No-show',
      noDialIn: 'Keine Einwahl',
      pleaseReschedule: '(Bitte verschiebe den Termin)',
      contactSupport: '(Kontaktiere den Support)',
      tooShort: 'Gespräch zu kurz',
      coachNoShow: 'Nichterscheinen (no-show) Coach',

      // Call move modal
      shortExplanation: 'Kurze Erklärung',
      moveCallSuccess: 'Gespräch verschoben, E-Mails gesendet.',
      moveCall: 'Gespräch verschieben',
      moveCallWarning: 'Bitte verschiebe ein Gespräch nur, nachdem Du die Änderung mit Deinem Klienten und ggf. mit Deinem Supervisor abgestimmt hast. Nach dem Speichern werden E-Mails an alle Beteiligten versendet.',
      clientRequest: 'Von Klient gewünscht',
      other: 'Sonstiges',
      clientNoShow: 'Nichterscheinen (no-show)',
      techIssues: 'Technische Probleme',

      // Call cancel modal
      resetCall: 'Gespräch zurücksetzen',
      resetCallWarning: 'Ein bestätigter Termin sollte nur in Ausnahmefällen (z.B. Krankheit, Notfall) abgesagt werden. Wenn Du die Absage bestätigst, wird das Gespräch zurückgesetzt und es werden E-Mails an alle Teilnehmer versendet.',
      resetCallSuccess: 'Gespräch zurückgesetzt, E-Mails gesendet.',
      reason: 'Grund',
      resetReasonPlaceholder: 'Warum wird dieses Gespräch zurückgesetzt?',
      threeDots: '...',

      discardCall: 'Gespräch Absagen',
      discardCallDetails: 'Wenn das Gespräch nicht nachgeholt werden soll, kannst Du es endgültig absagen.',
      discardCallWarning: 'Die Absage eines Gesprächs ist final, Du kannst es dann nicht mehr neu terminieren. Willst Du das Gespräch sicher absagen?',
      discardCallSuccess: 'Gespräch abgesagt, E-Mails gesendet.',
      discardReasonPlaceholder: 'Warum soll dieses Gespräch abgesagt werden?',

      event: {
        Reset: 'Zurückgesetzt',
        Move: 'Umgeplant',
        Fail: 'Gescheitert',
        Obsolete: 'Abgesagt',
        Schedule: 'Geplant',
      },
    },
    availabilities: {
      // Weekly avail picker
      timePreferencesTitle: 'Deine Zeitpräferenz für Supervision',
      timePreferencesDes: 'Supervisionsgespräche werden "ad hoc" bei allen Supervisoren angefragt. Lass uns wissen, zu welchen Zeiten Du grundsätzlich für Supervision verfügbar bist. Diese Zeiten gelten wiederkehrend Woche für Woche. Falls Du länger im Urlaub bist oder in eine andere Zeitzone wechselst, passe die Zeiten bitte entsprechend an.',
      timePreferencesSaved: 'Zeitpräferenzen gespeichert',
      loadingTimePreferences: 'Zeitpräferenzen werden geladen...',
      from: 'von',
      to: 'bis',
      weekDays: {
        monday: 'Montag',
        tuesday: 'Dienstag',
        wednesday: 'Mittwoch',
        thursday: 'Donnerstag',
        friday: 'Freitag',
        saturday: 'Samstag',
        sunday: 'Sonntag',
      },

      // Daily avail picker
      cw: 'KW',
      availabilitiesCoachingTitle: 'Deine Verfügbarkeiten für Coaching',
      availabilitiesCoachingDes: 'Markiere alle 30-Minuten-Blöcke, an denen Du in den nächsten 14 Tagen Zeit für ein Coaching-Gespräch hast. Sobald Du fertig bist, drücke unten auf "Speichern".',
      availabilitiesSaved: 'Verfügbarkeiten gespeichert',
    },
    buttons: {
      save: 'Speichern',
      cancel: 'Abbrechen',
      confirm: 'Bestätigen',
      showInfo: 'Details anzeigen',
      move: 'Verschieben',
      discard: 'Verwerfen',
      reset: 'Zurücksetzen',
      logOut: 'Ausloggen',
    },
    projects: {
      clients: {
        one: '%{count} Teilnehmer',
        other: '%{count} Teilnehmende',
      },
      program: 'Programm:',
      inPreparation: 'in Vorbereitung',
      percentCompleted: '%{percent}% abgeschlossen',
      status: 'Status',
      ongoingProjects: 'Laufende Projekte',
      finishedProjects: 'Abgeschlossene Projekte',
      loadingProjects: 'Projekte laden...',
      noOngoingProjects: 'Du hast keine laufenden Projekte.',
      noFinishedProjects: 'Du hast keine abgeschlossenen Projekte.',
      backToProjects: 'Zurück zu Projekten',
      months: {
        one: '%{count} Monat',
        other: '%{count} Monate',
      },
      calls: {
        title: 'Anrufe',
        total: 'Gesamt',
        completed: 'Erledigt',
        upcoming: 'Demnächst',
        placeholder: 'Platzhalter',
        noShows: 'No-Shows',
        finishedPercentage: '%{percent}% beendet',
      },
      overview: {
        title: 'Übersicht',
        participants: 'Teilnehmer',
        coaches: 'Coaches',
        timeline: 'Zeitleiste',
        duration: 'Dauer',
        elapsed: '%{percent}% verstrichen',
      },
      engagement: {
        title: 'Engagement',
        chatUsage: 'Chat-Nutzung (Nachrichten/Woche)',
        callMoveRate: 'Verschiebungs-Rate',
        moduleCompletion: 'Modul-Nutzung',
        actionPlanUsage: 'Aktionsplan-Nutzung',
        overall: '%{percent}% insgesamt',
      },
      actions: {
        title: 'Aktionen',
        inviteNewParticipant: 'Neuen Teilnehmer einladen',
        generateReport: 'Bericht generieren',
      },
      participants: {
        title: 'Teilnehmer',
        searchPlaceholder: 'Vorname oder Code',
        filterByCohort: 'Nach Kohorte filtern:',
        firstName: 'Vorname',
        code: 'Code',
        coach: 'Coach',
        invited: 'Eingeladen',
        signedUp: 'Angemeldet',
      },
    },
    coachings: {
      ongoingCoachings: 'Laufende Coachings',
      finishedCoachings: 'Abgeschlossene Coachings',
      loadingCoachings: 'Coachings laden...',
      percentCompleted: '%{percent}% abgeschlossen',
      emptyListHint: {
        active: 'Du hast keine laufenden Coachings.',
        finished: 'Du hast keine abgeschlossenen Coachings.',
      },
      searchPlaceholder: 'Code',
    },
  }, // ui END
  screen: {
    Dashboards: 'Dashboards',
    Home: 'Home',

    Coaching: 'Coaching',
    Calls: 'Gespräche',
    Availabilities: 'Verfügbarkeiten',
    Coachings: 'Coachings',
    NotFound: 'Seite existiert nicht.',

    Resources: 'Ressourcen',
    Projects: 'Projekte',
  },
  error: {
    unknownError: 'Es ist etwas schief gelaufen',
    urlNotExist: 'Die angeforderte Seite existiert nicht.',
    noUserAccess: 'Du hast nicht die nötigen Zugriffsrechte für diese Seite.',
    minReqChars: 'Mindestens %{min} Zeichen',
    api: {
      ADMIN_ONLY: 'Benötigt Administrator-Zugang',
      AUTH_FAILED: 'Unbekannte Email-Adresse oder falsches Passwort.',
      AUTH_EXPIRED_TOKEN: 'Anmeldeschlüssel abgelaufen',
      AUTH_INVALID_TOKEN: 'Anmeldeschlüssel ungültig',
      AUTH_MISSING_TOKEN: 'Anmeldeschlüssel nicht gefunden',
      AUTH_MISSING_ROLE: 'Nicht erlaubt (benötigt erweiterte Zugangsrechte)',
      BAD_REQUEST: 'Fehlerhafte Anfrage',
      INCONSISTENT_PARAMS: 'Widersprüchliche Parameter',
      INVALID_AVAIL: 'Keine freie Verfügbarkeit für diese(n) Benutzer&Zeit',
      INVALID_MODULE: 'Kein gültiges Modul',
      TOO_MANY_REQUESTS: 'API-Anfragelimit überschritten',
      INVALID_TIME: 'Ausgewählte Zeit ist nicht gültig',
    },
    login: {
      pleaseEnterValidEmail: 'Bitte gib eine gültige E-Mail-Adresse ein.',
      passwordLengthWarning: 'Bitte zwischen 8 und 72 Zeichen',
    },
  },
  warning: {
    timezonePart1: 'Die Zeiten unten sind in der Zeitzone',
    timezonePart2: 'angegeben. Du kannst Deine präferierte Zeitzone in den App-Einstellungen verändern.',
  },
  date: {
    appointmentDate: 'dd, D. MMM Y',
    appointmentDateTime: 'D. MMM Y, HH:mm',
    appointmentDayDateTime: 'dd, D. MMM Y, HH:mm',
    dateRel: {
      sameDay: '[heute]',
      lastDay: '[gestern]',
      lastWeek: 'D. MMMM Y',
      sameElse: 'D. MMMM Y',
    },
    dateShort: 'DD. MMMM',
    dateTimeRel: {
      sameDay: '[heute] HH:mm',
      lastDay: '[gestern] [um] HH:mm',
      lastWeek: 'dd., HH:MM',
      sameElse: 'DD.MM.Y [um] HH:mm',
    },
    dateTimeRelShort: {
      sameDay: 'HH:mm',
      lastDay: '[Gestern]',
      lastWeek: 'D.M.YY',
      sameElse: 'D.M.YY',
    },
    dayMonthShort: 'D. MMM',
    monthYearShort: 'MM/\'YY',
    dayMonthYearShort: 'D. MMM Y',
    dayShort: 'dd',
    fullDate: 'DD.MM.Y',
    fullDateMonthName: 'D. MMMM Y',
    fullDateTime: 'LLLL',
    monthNameYear: 'MMMM Y',
    monthShort: 'MMM',
    shortDateTime: 'llll [Uhr]',
    time: 'H:mm',
    timeColon: 'H:mm',
    timeExpl: 'H:mm [Uhr]',
    timeSpan: '%{from} - %{to} Uhr',
    timeSpanShort: '%{from} - %{to}',
    formatedTime: '%{time}',
  },
  style: {
    scheduledCalls: {
      buttonMarginH: 10,
    },
  },
  other: {
    callStatuses: {
      placeholder: 'Noch nicht geplant',
      pending: 'Schwebend',
      confirmed: 'Demnächst',
      canceled: 'Abgesagt',
      finished: 'Abgeschlossen',
      failed: 'Fehlgeschlagen',
      obsolete: 'Veraltet',
    },
    callStatusWithDate: {
      placeholder: 'Noch nicht geplant',
      pending: 'Schwebend',
      confirmed: 'Demnächst am %{date}',
      canceled: 'Abgesagt am %{date}',
      finished: 'Abgeschlossen am %{date}',
      failed: 'Fehlgeschlagen am %{date}',
      obsolete: 'Veraltet on %{date}',
    },
    completedOn: 'Abgeschlossen am',
    notCompletedYet: 'Noch nicht abgeschlossen',
  },
};
