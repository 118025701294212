import React from 'react';
import {
  StyleSheet, Text, TextInput as RnTextInput, View, Animated,
} from 'react-native';
import Style from '../style';

type TextInputProps = {
  caption: string,
  hint?: string,
  rx?: RegExp,
  errMsg?: string,
  masked?: boolean,
  disabled?: boolean,
  testId?: string,
  submit?: () => void,
};

type TextInputState = {
  hasError: boolean,
};

// Note: This is a class component (rather than a much leaner function component)
// because we need to query validation state and imperatively trigger animations
// via refs.
class TextInput extends React.PureComponent<TextInputProps, TextInputState> {
  text = '';

  errTimeout?: NodeJS.Timeout;

  rx?: RegExp;

  shakeAnimation = new Animated.Value(0);

  input = React.createRef<RnTextInput>();

  constructor(props: TextInputProps) {
    super(props);
    this.state = {
      hasError: false,
    };
    this.rx = props.rx;
  }

  isValid = () => !!this.text.length && this.rx?.test(this.text);

  validate = (text: string) => {
    this.text = text;
    const valid = this.isValid();
    const { hasError } = this.state;
    if (valid) {
      this.setState({ hasError: false });
      if (this.errTimeout) clearTimeout(this.errTimeout);
    } else if (!valid && !hasError) {
      if (this.errTimeout) clearTimeout(this.errTimeout);
      this.errTimeout = setTimeout(() => {
        this.setState({ hasError: true });
      }, 1000);
    }
  };

  checkAndComplain = () => {
    const valid = this.isValid();
    this.setState({ hasError: !valid });
    if (!valid) {
      this.input.current?.focus();
      this.shake();
    }
    return valid;
  };

  shake = () => {
    Animated.sequence([
      Animated.timing(this.shakeAnimation, { toValue: 10, duration: 100, useNativeDriver: true }),
      Animated.timing(this.shakeAnimation, { toValue: -10, duration: 100, useNativeDriver: true }),
      Animated.timing(this.shakeAnimation, { toValue: 10, duration: 100, useNativeDriver: true }),
      Animated.timing(this.shakeAnimation, { toValue: 0, duration: 100, useNativeDriver: true }),
    ]).start();
  };

  render() {
    const {
      caption, masked, hint, errMsg, disabled, testId, submit,
    } = this.props;
    const opacity = disabled ? 0.7 : 1.0;
    const { hasError } = this.state;
    return (
      <View style={[styles.wrapper, { opacity }]}>
        <Text style={styles.caption} selectable={false}>{caption}</Text>
        <View>
          <RnTextInput
            style={[styles.input, hasError && styles.inputError]}
            secureTextEntry={masked}
            placeholder={hint}
            placeholderTextColor={Style.Color.Gray300}
            onChangeText={this.validate}
            ref={this.input}
            editable={!disabled}
            testID={testId}
            onKeyPress={(event) => {
              if (event.nativeEvent.key === 'Enter' && submit) {
                submit();
              }
            }}
          />
          {hasError
            && (
              <View style={styles.errIcon}>
                <Style.Icon.Warning fill={Style.Color.Tertiary} />
              </View>
            )}
        </View>
        {hasError
          && (
            <Animated.Text
              style={[styles.errMsg, { transform: [{ translateX: this.shakeAnimation }] }]}
            >
              {errMsg}
            </Animated.Text>
          )}
      </View>
    );
  }
}
export default TextInput;

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
  },
  caption: {
    ...Style.Text.Small,
    color: Style.Color.Gray400,
    marginBottom: 5,
  },
  input: {
    width: '100%',
    height: 40,
    borderWidth: 1,
    borderRadius: 4,
    borderColor: Style.Color.Primary,
    outlineColor: Style.Color.Primary,
    ...Style.Text.Normal,
    color: Style.Color.Primary,
    backgroundColor: Style.Color.White,
    paddingHorizontal: 10,
    marginBottom: 18,
  },
  inputError: {
    color: Style.Color.Tertiary,
    borderColor: Style.Color.Tertiary,
    outlineColor: Style.Color.Tertiary,
    marginBottom: 0,
  },
  errIcon: {
    width: 36,
    height: 36,
    position: 'absolute',
    top: 2,
    right: 4,
    backgroundColor: Style.Color.White,
    justifyContent: 'center',
    alignItems: 'center',
  },
  errMsg: {
    height: 18,
    ...Style.Text.Tiny,
    color: Style.Color.Tertiary,
    alignSelf: 'flex-end',
    paddingTop: 3,
    paddingBottom: 2,
  },
});
