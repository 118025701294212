import React, { useContext } from 'react';
import {
  StyleSheet, View, Text, Linking,
} from 'react-native';
import moment from 'moment';
import _ from 'lodash';

import ModuleTitle from '../ModuleTitle';
import Style from '../../style';
import I18n, { formatDate } from '../../i18n';
import { RootStoreContext } from '../../stores/RootStore';
import { fetchUser } from '../../api/Users';
import { isCallMoveable } from '../../util/helpers';
import ProgramName from '../ProgramName';
import { fetchCallDetails } from '../../api/Calls';
import CallLogs from './CallLogs';
import { Coaching, HeaderItemValue, StatusSymbol } from '../../types/Coachings';
import LoadingIndicator from '../LoadingIndicator';
import { getTooltipInfo } from '../../util/coachings';

const ONE_MINUTE = 60 * 1000;

interface PartnerInfoProps {
  partnerId: string,
}

const PartnerInfo = ({ partnerId } : PartnerInfoProps) => {
  const { data: partnerDetails } = fetchUser(partnerId).swr;
  if (!partnerDetails) return null;

  return (
    <View style={styles.infoElements}>
      <Style.Icon.UserCircle width={24} height={24} fill={Style.Color.Primary} />
      <Text style={styles.infoElementText}>{partnerDetails?.firstName}</Text>
      <View style={styles.userCodeContainer}>
        <Text style={styles.textUserCode}>{partnerDetails?.code}</Text>
      </View>
    </View>
  );
};

interface CallInfoModalProps {
  callId: string;
  checkpointInfo?: {
    item: HeaderItemValue,
    coaching?: Coaching;
  }
}

const CallInfoModal = ({ callId, checkpointInfo }: CallInfoModalProps) => {
  const store = useContext(RootStoreContext);
  const myId = store.auth.userId;

  const { data: callDetails } = fetchCallDetails(callId).swr;
  if (!callDetails) return <LoadingIndicator text={I18n.t('ui.calls.loadingCalls')} />;
  const durInMinutes = Math.round(callDetails.duration / ONE_MINUTE);
  const isPastCall = moment().isAfter(callDetails.start);
  let pin = callDetails.pin || '';
  pin = pin.toString().replace(/.{1,2}(?=(.{3})+$)/g, '$& ');

  const partnerId = callDetails.clientId === myId ? callDetails.coachId : callDetails.clientId;
  const checkpoint = !!checkpointInfo;

  const checkConfError = () => {
    if (!callDetails) return '';
    if (callDetails.status !== 'failed' || !callDetails.logs) return '';

    const lastFailLog = _(callDetails.logs).sortBy('time').findLast({ event: 'Fail' });
    if (lastFailLog?.reason) {
      return I18n.t(`ui.calls.${lastFailLog.reason}`, { defaultValue: lastFailLog.reason });
    }
    return '';
  };

  const renderCallStatus = () => {
    if (!checkpointInfo?.item?.value) return null;
    const { icon, color } = checkpointInfo.item.value as StatusSymbol;
    return (
      <>
        {!isPastCall && <View style={styles.itemDivider} />}
        <View style={styles.infoElements}>
          <Style.Icon.Filled.ByName
            name={icon}
            width={24}
            height={25}
            fill={color}
          />
          <Text style={styles.infoElementText}>
            {getTooltipInfo(
              checkpointInfo.item.key, checkpointInfo.item.type, checkpointInfo.coaching,
            )}
          </Text>
        </View>
      </>
    );
  };

  const renderCallDur = () => {
    let realDur = 0;
    if (callDetails.realDuration) realDur = _.round(callDetails.realDuration / ONE_MINUTE);
    const confError = checkConfError();

    return (
      <>
        <View style={styles.itemDivider} />
        {checkpoint && renderCallStatus()}
        <View style={styles.infoElements}>
          <Style.Icon.HourglassLow width={24} height={24} fill={Style.Color.Primary} />
          <Text style={styles.infoElementText}>
            {I18n.t('ui.calls.nMinutes', { count: realDur })}
          </Text>
          <Text style={styles.moreInfo}>
            {I18n.t('ui.calls.actualDuration')}
          </Text>
        </View>

        {(confError !== '' && !checkpoint) && (
          <View style={styles.infoElements}>
            <Style.Icon.Warning width={24} height={24} fill={Style.Color.Primary} />
            <Text style={styles.infoElementText}>
              {confError}
            </Text>
            {isCallMoveable(callDetails) ? (
              <Text style={styles.moreInfo}>
                {I18n.t('ui.calls.pleaseReschedule')}
              </Text>
            ) : (
              <Text style={styles.linkedText} onPress={() => Linking.openURL('https://support.thenextwe.com/en/')}>
                {I18n.t('ui.calls.contactSupport')}
              </Text>
            )}
          </View>
        )}
      </>
    );
  };

  const renderPhoneDetails = () => (
    <>
      <View style={styles.itemDivider} />
      <View style={styles.phoneNo}>
        <View style={styles.callCodeIcon}>
          <Style.Icon.Hash width={24} height={24} fill={Style.Color.Primary} />
        </View>
        <Text style={styles.phoneNoText}>
          {callDetails.number}
        </Text>
      </View>

      <View style={styles.callCode}>
        <View style={styles.callCodeIcon}>
          <Style.Icon.Key width={24} height={24} fill={Style.Color.Primary} />
        </View>
        <View style={styles.passwordContainer}>
          <Text style={styles.passwordText}>
            {pin}
          </Text>
        </View>
      </View>
    </>
  );

  return (
    <View style={styles.container}>
      <View style={styles.infoContainer}>
        <ModuleTitle
          key={callDetails.moduleId}
          moduleId={callDetails.moduleId}
          style={styles.header}
        />
        {callDetails.start && (
          <View style={styles.infoElements}>
            <Style.Icon.Calendar width={24} height={24} fill={Style.Color.Primary} />
            <Text style={styles.infoElementText}>
              {formatDate('date.appointmentDateTime', callDetails.start)}
            </Text>
          </View>
        )}
        <ProgramName
          userId={callDetails.clientId}
          moduleKey={callDetails.moduleKey}
          style={styles.infoElementText}
          showIcon
        />
        <View style={styles.infoElements}>
          <Style.Icon.Hourglass width={24} height={24} fill={Style.Color.Primary} />
          <Text style={styles.infoElementText}>
            {I18n.t('ui.calls.nMinutes', { count: durInMinutes })}
          </Text>
        </View>
        {partnerId && !checkpoint && (
          <PartnerInfo partnerId={partnerId} />
        )}
      </View>
      {pin !== '' && !checkpoint && renderPhoneDetails()}
      {!isPastCall && checkpoint && renderCallStatus()}
      {isPastCall && renderCallDur()}
      <CallLogs callDetails={callDetails} />
    </View>
  );
};

export default CallInfoModal;

const styles = StyleSheet.create({
  container: {
    paddingBottom: 30,
  },
  infoContainer: {
    width: '100%',
    alignItems: 'flex-start',
  },
  header: {
    ...Style.Text.Heading2,
    color: Style.Color.Black,
    textAlign: 'center',
    width: '100%',
    marginBottom: 25,
  },
  infoElements: {
    flexDirection: 'row',
    marginLeft: 50,
    marginBottom: 15,
    alignItems: 'center',
  },
  infoElementText: {
    ...Style.Text.Normal,
    color: Style.Color.Gray800,
    marginLeft: 15,
  },
  noteText: {
    ...Style.Text.NormalItalic,
    color: Style.Color.Gray800,
    marginLeft: 40,
    marginRight: 20,
  },
  itemDivider: {
    borderBottomColor: Style.Color.Gray200,
    borderBottomWidth: 1,
    marginHorizontal: 28,
    marginTop: 10,
    marginBottom: 25,
  },
  phoneNo: {
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 14,
    flexDirection: 'row',
    left: -12,
  },
  callCode: {
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 15,
    flexDirection: 'row',
    left: -12,
  },
  callCodeElements: {
    flexDirection: 'row',
  },
  callCodeIcon: {
    left: -16,
  },
  phoneNoText: {
    ...Style.Text.Caption,
    color: Style.Color.Black,
  },
  passwordContainer: {
    paddingVertical: 5,
    width: 138,
    height: 30,
    backgroundColor: Style.Color.Primary,
    borderRadius: 15,
    alignItems: 'center',
    justifyContent: 'center',
  },
  passwordText: {
    ...Style.Text.Caption,
    color: Style.Color.White,
  },

  userCodeContainer: {
    backgroundColor: Style.Color.Gray200,
    paddingHorizontal: 4,
    paddingVertical: 2,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    marginLeft: 5,
  },
  textUserCode: {
    ...Style.Text.Code,
    color: Style.Color.Gray600,
  },
  moreInfo: {
    ...Style.Text.NormalItalic,
    color: Style.Color.Gray400,
    marginLeft: 3,
  },
  linkedText: {
    ...Style.Text.NormalItalic,
    color: Style.Color.Gray400,
    marginLeft: 3,
    textDecorationLine: 'underline',
  },
  moveContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 5,
  },
});
