import React from 'react';
import { StyleSheet, View } from 'react-native';
import Style from '../style';
import SideMenuBar, { GoBackButton } from '../components/SideMenuBar';

interface SLProps {
  children: React.ReactNode,
  goBackButton?: GoBackButton,
}

export default function ScreenLayout({ children, goBackButton }: SLProps) {
  return (
    <View style={styles.container}>
      <SideMenuBar goBackButton={goBackButton}>
        {children}
      </SideMenuBar>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Style.Color.Gray100,
  },
});
