import useSWR from 'swr';
import { createFetcher } from './Requests';
import { User, ErrorRes } from '../types';

type FetchUsersQuery = {
  redacted?: boolean;
  [key: string]: unknown;
};

export const fetchUsers = ({ redacted = false, ...query }: FetchUsersQuery = {}) => {
  const { url, fetcher } = createFetcher('/users', { redacted, ...query });
  return { swr: useSWR<User[], ErrorRes>(url, fetcher), url };
};

export const fetchUser = (userId: string) => {
  const { url, fetcher } = createFetcher(`/users/${userId}`);
  return { swr: useSWR<User, ErrorRes>(url, fetcher), url };
};
